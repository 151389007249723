<script>
import {
  ARTICLE_STATUS_PUBLISHED,
  ARTICLE_STATUS_REJECTED,
  ARTICLE_STATUS_AWAITING_MODERATION,
} from "@/global/constants";
import {moderateArticle} from "@/api/articles";
import moment from "moment";

export default {
  name: "ArticlesModerationModal",

  props: {
    article: {
      required: true,
    },
  },

  data() {
    return {
      message: '',
      loading: false,
      ARTICLE_STATUS_AWAITING_MODERATION,
      ARTICLE_STATUS_PUBLISHED,
      ARTICLE_STATUS_REJECTED,
    }
  },

  methods: {
    cancel() {
      this.$emit('close');
    },

    async decline() {
      this.loading = true;
      await moderateArticle(this.article.id, ARTICLE_STATUS_REJECTED, this.message);
      this.loading = false;
      this.$emit('updated');
      this.cancel();
    },

    async publish() {
      this.loading = true;
      await moderateArticle(this.article.id, ARTICLE_STATUS_PUBLISHED, this.message);
      this.loading = false;
      this.$emit('updated');
      this.cancel();
    },

    moment(date) {
      return moment(date);
    },
  },
};
</script>

<template>
  <a-modal
      title="Модерация статьи"
      :visible="true"
      :confirmLoading="loading"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <template v-if="article.moderations.length">
        <a-list
            class="comment-list"
            item-layout="horizontal"
            :data-source="article.moderations"
        >
          <a-list-item slot="renderItem" slot-scope="item, index">
            <a-comment>
              <a slot="author">{{ item.user.name }}</a>

              <p slot="content">
                {{ item.message }}
              </p>

              <a-tooltip slot="datetime" :title="moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')">
                <span>{{ moment(item.created_at).fromNow() }}</span>
              </a-tooltip>
            </a-comment>
          </a-list-item>
        </a-list>
      </template>

      <a-textarea
          placeholder="Оставьте ваш комментарий"
          v-model="message"
          style="height: 150px; margin-bottom: 20px"
      />
    </a-spin>

    <template #footer>
      <a-button key="back" @click="cancel">
        Закрыть
      </a-button>
      <a-button
          v-if="article.status === ARTICLE_STATUS_AWAITING_MODERATION"
          key="submit"
          type="danger"
          :loading="loading"
          @click="decline"
      >
        Отклонить
      </a-button>
      <a-button
          v-if="![ARTICLE_STATUS_PUBLISHED].includes(article.status)"
          key="submit"
          type="primary"
          :loading="loading"
          @click="publish"
      >
        Опубликовать
      </a-button>
    </template>
  </a-modal>
</template>

<style scoped lang="scss">

</style>