<template>
  <div>
    <PageTitle title="Категории статей">
      <router-link to="/articles/category/add">
        <a-button size="large" type="primary" icon="plus">Добавить категорию</a-button>
      </router-link>
    </PageTitle>
    <a-layout-content class="page__content">
      <a-table
        :columns="columns"
        :pagination="false"
        :dataSource="articlesCategories"
        :loading="hasLoadArticlesCategories"
        rowKey="id"
      >
        <template slot="parent-category" slot-scope="scope, record">
          <a-tag color="blue" v-if="record.parentId">{{
            articlesCategories.find(c => c.id === Number(record.parentId)).translations.ru.title
          }}</a-tag>
        </template>
        <template slot="actions" slot-scope="id, record">
          <a :href="record.url"  target="_blank">
            <a-button
              type="primary"
              class="gray-btn"
              icon="eye"
              style="margin-right: 10px"
            ></a-button>
          </a>

          <router-link :to="{ path: `/articles/category/${record.id}/edit` }">
            <a-button
              type="primary"
              class="gray-btn"
              icon="edit"
              style="margin-right: 10px"
            ></a-button>
          </router-link>

          <a-button
            type="primary"
            class="gray-btn"
            icon="delete"
            style="margin-right: 10px"
            @click="deleteCategory(record.id)"
          ></a-button>
        </template>

        <template slot="author" slot-scope="author">
          {{ author.name }} </template
        >w
      </a-table>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import { articles } from '@/api/articles';

export default {
  components: { PageTitle },
  data() {
    return {
      articlesCategories: [],
      hasLoadArticlesCategories: false,
      columns: [
        {
          title: 'Название',
          dataIndex: 'translations.ru.title',
          key: 'name',
        },
        {
          title: 'Родительская категория',
          dataIndex: 'parentCategory',
          key: 'parentCategory',
          scopedSlots: { customRender: 'parent-category' },
        },
        {
          title: 'Действия',
          dataIndex: 'parentCategory',
          key: 'parentCategory',
          scopedSlots: { customRender: 'actions' },
        },
      ],
    };
  },
  mounted() {
    this.getArticleCategories();
  },
  methods: {
    async getArticleCategories() {
      this.hasLoadArticlesCategories = true;
      await articles
        .getArticleCategory()
        .then((res) => res.json())
        .then(({ data }) => {
          this.articlesCategories = data;
          this.hasLoadArticlesCategories = false;
        });
    },

    deleteCategory(id) {
      this.$confirm({
        title: `Вы действительно хотите удалить данную категорию ?`,
        content:
          'Вы не сможете восстановить ее после удаления, только добавить еще раз.',
        onOk: async () => {
          await articles.deleteArticleCategory(id).then(() => {
            this.$message.success('Категория успешна удалена :)', 2);
            this.getArticleCategories();
          });
        },
      });
    },
  },
};
</script>

<style lang="scss"></style>
