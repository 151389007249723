var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Редактирование комментария"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-spin', {
    attrs: {
      "tip": "Loading...",
      "spinning": _vm.loading
    }
  }), !_vm.loading ? [_c('div', {
    staticClass: "change__comment__info"
  }, [_c('b', [_vm._v("Пользователь: " + _vm._s(_vm.comment.user && _vm.comment.user.name))])]), _c('div', {
    staticClass: "change__comment__info"
  }, [_c('b', [_vm._v("Дата создания: ")]), _vm._v(_vm._s(_vm.comment.created_at) + " ")]), _c('div', {
    staticClass: "change__comment__info"
  }, [_c('b', [_vm._v("Дата публикации: ")]), _vm._v(_vm._s(_vm.comment.published_at) + " ")]), _vm.commentId ? _c('div', {
    staticClass: "change__comment__info"
  }, [_c('b', [_vm._v("Оценка: ")]), _vm._v(_vm._s(_vm.comment.rating) + " ")]) : _vm._e(), _c('div', {
    staticClass: "change__comment__info"
  }, [_c('b', [_vm._v("Локаль: ")]), _vm._v(_vm._s(_vm.comment.locale) + " ")]), _c('a-textarea', {
    staticStyle: {
      "height": "120px"
    },
    model: {
      value: _vm.comment.text,
      callback: function callback($$v) {
        _vm.$set(_vm.comment, "text", $$v);
      },
      expression: "comment.text"
    }
  }), _c('a-button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" Сохранить ")]), _vm.comment.mark_as_spam ? _c('a-button', {
    staticClass: "orange-btn",
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.removeCommentFromSpam
    }
  }, [_vm._v(" Убрать из спама ")]) : _c('a-button', {
    staticClass: "orange-btn",
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.seCommentToSpam
    }
  }, [_vm._v(" Отметить как спам ")])] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }