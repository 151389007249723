import './global/authInterceptor';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {
  Affix,
  Alert,
  Avatar,
  Breadcrumb,
  Button,
  Collapse,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  List,
  message,
  Menu,
  Modal,
  Pagination,
  Popover,
  Radio,
  Row,
  Select,
  Spin,
  Steps,
  Switch,
  Table,
  Tabs,
  Tag,
  TimePicker,
  Tooltip,
  Upload,
  Card,
  Dropdown,
  Comment,
} from 'ant-design-vue';
import moment from 'moment';
import CKEditor from 'ckeditor4-vue';
import VueScrollTo from 'vue-scrollto';
import 'ant-design-vue/dist/antd.css';
import '@/assets/css/main.scss';
import './filters/dateFormat';

// Настройки
moment.locale('ru');
Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;

Vue.use(CKEditor);
Vue.use(VueScrollTo);

// UI
Vue.use(List);
Vue.use(Avatar);
Vue.use(Alert);
Vue.use(Spin);
Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Menu);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Pagination);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Input);
Vue.use(Upload);
Vue.use(Form);
Vue.use(Checkbox);
Vue.use(Tag);
Vue.use(Radio);
Vue.use(Tooltip);
Vue.use(InputNumber);
Vue.use(Layout);
Vue.use(Collapse);
Vue.use(Select);
Vue.use(DatePicker);
Vue.use(Steps);
Vue.use(TimePicker);
Vue.use(Breadcrumb);
Vue.use(Switch);
Vue.use(Affix);
Vue.use(Popover);
Vue.use(Card);
Vue.use(Dropdown);
Vue.use(Comment);

Vue.config.productionTip = false;

Vue.config.silent = true;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
