
// @ts-nocheck
import { Component, Vue, Prop } from 'vue-property-decorator';
import PageTitle from '@/components/Global/PageTitle.vue';
import { applications } from '@/api';
import { Language } from '@/types/applications';
import { languagesRoute } from '@/components/Applications/routes';

@Component({ name: 'Languages', components: { PageTitle } })
export default class AddLanguage extends Vue {
  @Prop() public id!: number;

  title: string = '';
  action: string = '';

  baseLanguage: Language = {
    id: 0,
    name: '',
    label: '',
    ietfBcp47: '',
  };

  /**
   * @description Обработка ошибок
   * @param {string} message
   * @return {function}
   */
  handleErrors(message: string) {
    return ({ errors, code }: any) => {
      if (errors && code === 422) {
        Object.values(errors).forEach((values: any) => {
          values.forEach((value: string) => {
            this.$message.error(value);
          });
        });
      } else {
        this.$message.success(message, 2);

      }
    };
  }

  mounted() {
    if (this.id) {
      this.action = 'Сохранить';
      this.title = 'Редактировать язык';
      this.getLanguageById();
    } else {
      this.action = 'Добавить';
      this.title = 'Добавить язык';
    }
  }

  /**
   * @description Получение языка по id
   * @return {Promise<any>}
   */
  private async getLanguageById(): Promise<any> {
    await applications
      .getLanguageById(this.id)
      .then((res) => res.json())
      .then(({ data }) => {
        this.baseLanguage = data;
      });
  }

  /**
   * @description Метод по клику на кнопку (текущего действия)
   * @return {Promise<any>}
   */
  private async handleSubmit(): Promise<any> {
    if (this.id) {
      await applications
        .updateLanguageById(this.id, this.baseLanguage)
        .then((res) => res.json())
        .then(this.handleErrors('Язык успешно обновлен :)'));
    } else {
      await applications
        .createLanguage(this.baseLanguage)
        .then((res) => res.json())
        .then(this.handleErrors('Язык успешно создан :)'));
    }
  }
}
