<template>
  <div>
    <PageTitle title="Комментарии приложений">
      <router-link :to="{name:'commentsAppsCreate'}">
        <a-button size="large" icon="plus" type="primary">
          Добавить комментарий
        </a-button>
      </router-link>
    </PageTitle>
    <a-layout-content class="page__content">
      <a-row>
        <h3>Поиск</h3>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form :form="form" @submit.prevent="handleSubmit">
            <a-input
              placeholder="Поиск"
              size="large"
              v-on:keyup.enter.prevent="handleSubmit"
              v-model="search.text"
            />
          </a-form>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-select
              show-search
              allow-clear
              :value="userNameQuery"
              placeholder="Имя автора"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="usersSearchDataSearching ? undefined : 'Не найден'"
              size="large"
              @search="handleUserNameSearch"
              @change="handleUserNameChange"
            >
              <a-spin v-if="usersSearchDataSearching" slot="notFoundContent" size="small"/>

              <a-select-option
                v-for="user in usersSearchData"
                :key="user.id"
              >
                {{ user.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-select
              show-search
              allow-clear
              :value="appNameQuery"
              placeholder="Название приложения"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="appsSearchDataSearching? undefined : 'Не найдено'"
              size="large"
              @search="handleAppNameSearch"
              @change="handleAppNameChange"
            >
              <a-spin v-if="appsSearchDataSearching" slot="notFoundContent" size="small"/>

              <a-select-option
                v-for="app in appsSearchData"
                :key="app.id"
              >
                {{ app.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="5">
          <a-form-item>
            <a-select
              placeholder="Выберите статус"
              size="large"
              v-model="search.status"
            >
              <a-select-option value="" key="0"
              >Выберите статус
              </a-select-option>

              <a-select-option value="1" key="2">Активно</a-select-option>
              <a-select-option value="2" key="3">Спам</a-select-option>
              <a-select-option value="3" key="4">Не спам</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="7">
          <a-range-picker
            size="large"
            v-model="dates"
            :placeholder="['Начало', 'Конец']"
          ></a-range-picker>
        </a-col>
        <a-col :span="24" style="margin-top: 25px">
          <a-button
            icon="search"
            size="large"
            type="primary"
            @click="handleSubmit"
            style="margin-right: 25px"
          >Поиск
          </a-button>
          <a-button size="large" @click="resetFilter">Сбросить</a-button>
        </a-col>
      </a-row>
    </a-layout-content>
    <a-layout-content class="page__content">
      <a-table
        :columns="columns"
        :pagination="false"
        :dataSource="comments.data"
        :loading="hasLoadComments"
        @change="onChange"
        rowKey="id"
        :rowClassName="rowClassName"
      >
        <template slot="operation" slot-scope="id, review">
          <a-row>
            <a :href="review.url" target="_blank">
              <a-button
                type="primary"
                class="gray-btn"
                icon="eye"
                style="margin-right: 10px"
              />
            </a>

            <router-link :to="{name:'CommentsAppsChange', params: {commentId: review.id} }">
              <a-button
                type="primary"
                class="gray-btn"
                icon="edit"
                style="margin-right: 10px"
              />
            </router-link>

            <a-tooltip>
              <template slot="title">
                Ответить
              </template>
              <a-button
                type="primary"
                class="gray-btn"
                icon="form"
                @click="openAnswerModal(review)"
              />
            </a-tooltip>

            <a-tooltip v-if="review.markAsSpam === 0">
              <template slot="title">
                Отметить как спам
              </template>
              <a-button
                type="danger"
                class="gray-btn"
                icon="pause-circle"
                v-if="review.markAsSpam === 0"
                @click="handleSetCommentToSpam(review.id)"
              />
            </a-tooltip>

            <a-tooltip v-else>
              <template slot="title">
                Убрать из спама
              </template>
              <a-button
                type="primary"
                icon="play-circle"
                class="gray-btn"
                @click="removeCommentFromSpam(review.id)"
              />
            </a-tooltip>

            <a-button
              @click="handleRemoveComment(review.id)"
              type="danger"
              class="gray-btn"
              icon="delete"
            />
          </a-row>
        </template>

        <template slot="rating" slot-scope="rating">
          <p style="color: #1BA305">{{ rating }}</p>
        </template>

        <template slot="user" slot-scope="user">
          <a-button
            v-if="user"
            type="link"
            @click="filterByUser(user.id, user.name)"
          >
            {{ user.name }}
          </a-button>
        </template>

        <template slot="text" slot-scope="text">
          <p v-if="text.length > 150">{{ text.slice(0, 150) }} ...</p>
          <p v-if="text.length < 150">{{ text }}</p>
        </template>

        <template slot="application" slot-scope="application">
          <a-button
            v-if="application"
            type="link"
            @click="filterByApplication(application.id, application.name)"
          >
            {{ application.name }}
          </a-button>
        </template>
      </a-table>
      <a-row style="text-align:  right;">
        <a-pagination
          v-if="!hasLoadComments && comments.meta"
          @change="paginationChange"
          v-model="comments.meta.current_page"
          :total="comments.meta.total"
          :pageSize="comments.meta.per_page"
          style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>

    <comments-answer-modal
      v-if="answerReview"
      :review="answerReview"
      @close="closeAnswerModal"
      @created="handleSubmit"
    />
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import {applications} from '@/api';
import {searchUsers} from '@/api/users';
import debounce from 'lodash/debounce';
import {searchApps} from '@/api/Applications/app';
import CommentsAnswerModal from '@/components/Comments/CommentsAnswerModal.vue';

export default {
  components: {
    CommentsAnswerModal,
    PageTitle,
  },
  data() {
    return {
      answerReview: undefined,
      comments: {},
      hasLoadComments: false,
      dates: [],

      userNameQuery: undefined,
      usersSearchData: [],
      usersSearchDataSearching: false,
      usersSearchController: undefined,

      appNameQuery: undefined,
      appsSearchData: [],
      appsSearchDataSearching: false,
      appsSearchController: undefined,

      search: {
        text: '',
        status: '',
        page: 1,
        user_id: undefined,
        app_id: undefined,
      },
      sort: {
        [`order[created_at]`]: 'desc',
      },
      columns: [
        {
          title: 'Приложение',
          dataIndex: 'application',
          key: 'application',
          sorter: false,
          scopedSlots: {customRender: 'application'},
          width: 200,
        },
        {
          title: 'Отзыв',
          dataIndex: 'text',
          key: 'text',
          sorter: false,
        },
        {
          title: 'Локаль',
          dataIndex: 'locale',
          key: 'locale',
          sorter: false,
        },
        {
          title: 'Автор',
          dataIndex: 'user',
          key: 'user',
          scopedSlots: {customRender: 'user'},
          sorter: false,
          width: 200,
        },
        {
          title: 'Создан',
          dataIndex: 'created_at',
          key: 'created_at',
          width: 180,
          sorter: true,
        },
        {
          title: 'Опубликован',
          dataIndex: 'published_at',
          key: 'published_at',
          width: 180,
          sorter: false,
        },
        {
          title: 'Действия',
          dataIndex: 'actions',
          scopedSlots: {customRender: 'operation'},
          key: 'actions',
          width: 250,
        },
      ],
    };
  },
  created() {
    this.handleUserNameSearch = debounce(this.handleUserNameSearch, 800);
    this.handleAppNameSearch = debounce(this.handleAppNameSearch, 800);
  },
  methods: {
    handleSetCommentToSpam(id) {
      this.$confirm({
        title: 'Вы действительно хотите поместить данный отзыв в спам?',
        onOk: async () => {
          await applications
            .updateCommentSpam(id, {
              markAsSpam: 1,
            })
            .then(async (res) => {
              if (res.status === 200) {
                this.$message.success('Отзыв помещен в спам', 2);
                await this.getApplicationsComments();
              }
            });
        },
      });
    },

    removeCommentFromSpam(id) {
      this.$confirm({
        title: 'Вы действительно хотите убрать из спама данный отзыв?',
        onOk: async () => {
          await applications
            .updateCommentSpam(id, {
              markAsSpam: 0,
            })
            .then(async (res) => {
              if (res.status === 200) {
                this.$message.success('Отзыв убран из спама', 2);
                await this.getApplicationsComments();
              }
            });
        },
      });
    },

    handleRemoveComment(id) {
      this.$confirm({
        title: 'Вы действительно хотите удалить данный отзыв?',
        content:
          'Вы не сможете восстановить данный отзыв после удаления, только добавить еще раз.',
        onOk: async () => {
          await applications
            .deleteCommentById(id)
            .then(async (res) => {
              if (res.status === 200) {
                this.$message.success('Отзыв успешно удален :)', 2);
                await this.getApplicationsComments();
              }
            });
        },
      });
    },

    async handleSubmit() {
      this.hasLoadComments = true;
      const [dateStart, dateEnd] = this.dates;
      const {text, user_id, app_id} = this.search;

      const params = {
        text,
        createdAt: {
          start: dateStart && dateStart.format('YYYY-MM-DD'),
          end: dateEnd && dateEnd.format('YYYY-MM-DD'),
        },
        user_id,
        app_id,
      };

      // TODO: Нужны человеческие статусы от Жени (когда нибудь), пока что так
      if (this.search.status === '0') {
        params.deleted_at = 1;
      } else if (this.search.status === '1') {
        params.deleted_at = 0;
      } else if (this.search.status === '2') {
        params.markAsSpam = 1;
      } else if (this.search.status === '3') {
        params.markAsSpam = 0;
      }

      this.search = {...this.search, ...params};

      await applications
        .searchComments({
          ...params,
          [`order[created_at]`]: 'desc',
          page: 1,
        })
        .then((res) => res.json())
        .then((comments) => {
          this.comments = comments;
          this.hasLoadComments = false;
        });
    },

    async getApplicationsComments() {
      this.hasLoadComments = true;
      await applications
        .searchComments({...this.search, ...this.sort})
        .then((res) => res.json())
        .then((comments) => {
          this.comments = comments;
          this.hasLoadComments = false;
        });
    },

    async sortCommentsByField(field, order) {
      this.hasLoadComments = true;

      this.sort = {};

      if (order) {
        this.sort = {[`order[${field}]`]: order};
      }

      await applications
        .searchComments({
          ...this.search,
          ...this.sort,
        })
        .then((res) => res.json())
        .then((data) => {
          this.hasLoadComments = false;
          this.comments = data;
        });
    },

    onChange(pagination, filters, sorter) {
      const {order, field} = sorter;
      this.sortCommentsByField(field, order ? order.replace('end', '') : null);
    },

    async paginationChange(page) {
      window.scrollTo(0, 0);
      this.search = {...this.search, page};
      await this.getApplicationsComments();
    },

    resetFilter() {
      this.search = {
        text: '',
        status: '',
        page: 1,
        user_id: undefined,
        app_id: undefined,
      };
      this.userNameQuery = undefined;
      this.appNameQuery = undefined;
      this.dates = [];
      this.sort = {
        [`order[created_at]`]: 'desc',
      };
      this.getApplicationsComments();
    },

    async handleUserNameSearch(value) {
      if (!value.trim().length) {
        return;
      }

      if (this.usersSearchDataSearching) {
        this.appsSearchController.abort();
      }

      this.appsSearchController = new AbortController();

      this.usersSearchDataSearching = true;
      this.usersSearchData = [];
      try {
        const res = await searchUsers({name: value, limit: 1000}, {signal: this.appsSearchController.signal});
        const json = await res.json();
        this.usersSearchData = json.data;
      } catch (err) {
        if (err.name !== 'AbortError') { // обработать ошибку от вызова abort()
          throw err;
        }
      } finally {
        this.usersSearchDataSearching = false;
      }

      if (this.search.user_id) {
        this.userNameQuery = this.search.user_id;
      }
    },

    handleUserNameChange(value) {
      this.userNameQuery = value;
      this.search.user_id = value;
      this.handleAppNameSearch(this.userNameQuery);
      this.handleSubmit();
    },

    async filterByUser(id, name) {
      this.search.user_id = id;
      this.userNameQuery = name;
      this.handleUserNameSearch(this.userNameQuery);
      this.handleSubmit();
    },

    async handleAppNameSearch(value) {
      if (!value.trim().length) {
        return;
      }

      if (true === this.appsSearchDataSearching) {
        this.appsSearchController.abort();
      }

      this.appsSearchController = new AbortController();
      this.appsSearchDataSearching = true;
      this.appsSearchData = [];
      try {
        const res = await searchApps(
          {
            name: value,
            limit: 1000,
            with_trashed: 1,
          },
          {signal: this.appsSearchController.signal});

        const json = await res.json();
        this.appsSearchData = json.data;
      } catch (err) {
        if (err.name !== 'AbortError') { // обработать ошибку от вызова abort()
          throw err;
        }
      } finally {
        this.appsSearchDataSearching = false;
      }

      if (this.search.app_id) {
        this.appNameQuery = this.search.app_id;
      }
    },

    handleAppNameChange(value) {
      this.appNameQuery = value;
      this.search.app_id = value;
      this.handleSubmit();
    },

    filterByApplication(id, name) {
      this.search.app_id = id;
      this.appNameQuery = name;
      this.handleSubmit();
    },

    rowClassName({ markAsSpam }, index) {
      return markAsSpam ? 'table-row-yellow' : '';
    },

    openAnswerModal(review) {
      this.answerReview = review;
    },

    closeAnswerModal() {
      this.answerReview = undefined;
    },
  },

  mounted() {
    this.getApplicationsComments();
  },
};
</script>

<style>
  tr td {
    word-break: break-word;
  }
</style>
