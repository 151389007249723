var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-form', {
    attrs: {
      "form": _vm.form
    }
  }, [_c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Заголовок"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['page.ru.title', {
        initialValue: _vm.page.ru.title,
        rules: [{
          required: true,
          message: 'Заголовок RU обязательное поле'
        }]
      }],
      expression: "[\n                'page.ru.title',\n                {\n                  initialValue: page.ru.title,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Заголовок RU обязательное поле'\n                    }\n                  ]\n                }\n              ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Заголовок RU"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Заголовок (en)"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['page.en.title', {
        initialValue: _vm.page.en && _vm.page.en.title,
        rules: [{
          required: true,
          message: 'Заголовок EN обязательное поле'
        }]
      }],
      expression: "[\n                'page.en.title',\n                {\n                  initialValue: page.en && page.en.title,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Заголовок EN обязательное поле'\n                    }\n                  ]\n                }\n              ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Заголовок EN"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Слаг (Пример: введенная строка 'slug' создаст ссылку 'mysite.com/page/slug'"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['slug', {
        initialValue: _vm.page.slug,
        rules: [{
          required: true,
          message: 'Слаг обязательное поле'
        }]
      }],
      expression: "[\n                'slug',\n                {\n                  initialValue: page.slug,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Слаг обязательное поле'\n                    }\n                  ]\n                }\n              ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Слаг"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "SEO title (ru)"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['page.ru.seo_title', {
        initialValue: _vm.page.ru.seo_title,
        rules: [{
          required: true,
          message: 'SEO title (ru) обязательное поле'
        }]
      }],
      expression: "[\n                'page.ru.seo_title',\n                {\n                  initialValue: page.ru.seo_title,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'SEO title (ru) обязательное поле'\n                    }\n                  ]\n                }\n              ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "SEO title (ru)"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "SEO title (en)"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['page.en.seo_title', {
        initialValue: _vm.page.en && _vm.page.en.seo_title,
        rules: [{
          required: true,
          message: 'SEO title (en) обязательное поле'
        }]
      }],
      expression: "[\n                'page.en.seo_title',\n                {\n                  initialValue: page.en && page.en.seo_title,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'SEO title (en) обязательное поле'\n                    }\n                  ]\n                }\n              ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "SEO title (en)"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "SEO description (ru)"
    }
  }, [_c('a-textarea', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['page.ru.seo_description', {
        initialValue: _vm.page.ru.seo_description,
        rules: [{
          required: true,
          message: 'SEO description (ru)'
        }]
      }],
      expression: "[\n                'page.ru.seo_description',\n                {\n                  initialValue: page.ru.seo_description,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'SEO description (ru)'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticStyle: {
      "height": "135px"
    },
    attrs: {
      "size": "large",
      "placeholder": "SEO description (ru)"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "SEO description (en)"
    }
  }, [_c('a-textarea', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['page.en.seo_description', {
        initialValue: _vm.page.en && _vm.page.en.seo_description,
        rules: [{
          required: true,
          message: 'SEO description (en)'
        }]
      }],
      expression: "[\n                'page.en.seo_description',\n                {\n                  initialValue: page.en && page.en.seo_description,\n\n                  rules: [\n                    {\n                      required: true,\n                      message: 'SEO description (en)'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticStyle: {
      "height": "135px"
    },
    attrs: {
      "size": "large",
      "placeholder": "SEO description (en)"
    }
  })], 1)], 1)], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    staticClass: "ant-form-item-required",
    attrs: {
      "label": "Текст (ru)"
    }
  }, [_c('ckeditor', {
    attrs: {
      "config": _vm.getCkeditorConfig()
    },
    model: {
      value: _vm.page.ru.content,
      callback: function callback($$v) {
        _vm.$set(_vm.page.ru, "content", $$v);
      },
      expression: "page.ru.content"
    }
  })], 1), _c('a-form-item', {
    staticClass: "ant-form-item-required",
    attrs: {
      "label": "Текст (en)"
    }
  }, [_c('ckeditor', {
    attrs: {
      "config": _vm.getCkeditorConfig()
    },
    model: {
      value: _vm.page.en && _vm.page.en.content,
      callback: function callback($$v) {
        _vm.$set(_vm.page.en && _vm.page.en, "content", $$v);
      },
      expression: "page.en && page.en.content"
    }
  })], 1)], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(_vm._s(_vm.action))])], 1), _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', [_c('a-switch', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['status', {
        initialValue: _vm.page.status,
        rules: [{
          required: true
        }]
      }],
      expression: "[\n                'status',\n                {\n                  initialValue: page.status,\n                  rules: [\n                    {\n                      required: true\n                    }\n                  ]\n                }\n              ]"
    }],
    staticStyle: {
      "margin-right": "10px"
    },
    model: {
      value: _vm.page.status,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "status", $$v);
      },
      expression: "page.status"
    }
  }), _vm._v(" Опубликовано ")], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }