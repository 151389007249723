<template>
  <div>
    <PageTitle title="Пользователи">
      <router-link to="/users/add">
        <a-button size="large" type="primary" icon="plus">Добавить пользователя</a-button>
      </router-link>
    </PageTitle>
    <a-layout-content class="page__content">
      <a-row>
        <h3>Поиск</h3>
      </a-row>

      <a-row :gutter="24">
        <a-col span="4">
          <a-input
              size="large"
              v-model="filter.email"
              v-on:keyup.enter="searchUsers"
              placeholder="Email"
          />
        </a-col>
        <a-col span="4">
          <a-input
              size="large"
              v-model="filter.name"
              v-on:keyup.enter="searchUsers"
              placeholder="Имя"
          />
        </a-col>
        <a-col span="4">
          <a-select
              size="large"
              defaultValue=""
              v-model="filter.role"
              placeholder="Выберите роль"
          >
            <a-select-option value="developer">Разработчик</a-select-option>
            <a-select-option value="user">Пользователь</a-select-option>
            <a-select-option value="content_manager">Контент менеджер</a-select-option>
            <a-select-option value="editor">Редактор</a-select-option>
            <a-select-option value="admin">Администратор</a-select-option>
            <a-select-option value="seo">SEO специалист</a-select-option>
            <a-select-option value="">Не выбрано</a-select-option>
          </a-select>
        </a-col>
        <a-col span="5">
          <a-select size="large" v-model="filter.active" placeholder="Выберите статус">
            <a-select-option value="">Выберите статус</a-select-option>
            <a-select-option value="0">Заблокирован</a-select-option>
            <a-select-option value="1">Активен</a-select-option>
            <a-select-option value="2">Удален</a-select-option>
          </a-select>
        </a-col>
        <a-col span="6">
          <a-checkbox v-model="filter.officialRepresentative">
            Официальный Представитель
          </a-checkbox>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col span="6">
          <a-range-picker
              :placeholder="['Начало', 'Конец']"
              v-model="dates"
              size="large"
          />
        </a-col>
        <a-col span="24" style="margin-top: 25px">
          <a-button
              size="large"
              type="primary"
              @click="searchUsers"
              style="margin-right: 25px"
              icon="search"
          >Поиск
          </a-button>
          <a-button size="large" @click="resetFilter">Очистить</a-button>
        </a-col>
      </a-row>
    </a-layout-content>
    <a-layout-content class="page__content">
      <a-table
          @change="onChange"
          :columns="columns"
          :loading="hasLoadUsers"
          :dataSource="users.data"
          :pagination="false"
          rowKey="id"
      >
        <span slot="email" slot-scope="user">
          {{ user.email }}
          <a-tag v-if="user.deletedAt" color="red">удален</a-tag>
        </span>

        <template v-slot:name="user">
          <a :href="user.url" target="_blank">{{ user.name }}</a>
        </template>

        <template slot="operation" slot-scope="user">
          <router-link :to="{ name:'user-edit', params: {id:user.id } } ">
            <a-button type="primary" class="gray-btn" icon="edit"></a-button>
          </router-link>

          <a-tooltip>
            <template slot="title">
              Login as user
            </template>
            <a
                :href="`${APP_URL}/api/admin/users/login-as?token=${token}&id=${user.id}`"
                target="_blank"
            >
              <a-button
                  v-if="!user.deleted_at"
                  type="default"
                  class="gray-btn"
                  style="margin-left: 5px"
                  icon="login"
              />
            </a>
          </a-tooltip>

          <a-button
              v-if="!user.deleted_at"
              type="danger"
              class="gray-btn"
              style="margin-left: 5px"
              @click="deleteUser(user)"
              icon="delete"
          />
        </template>
      </a-table>
      <a-row style="text-align: left;">
        <a-pagination
            v-if="users.meta"
            @change="paginationChange"
            v-model="users.meta.current_page"
            :total="users.meta.total"
            :pageSize="users.meta.per_page"
            style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>
import moment from 'moment';
import PageTitle from '@/components/Global/PageTitle.vue';
import {users, users as usersApi} from '@/api/users';
import {API_URL, APP_URL, DATE_FORMAT} from '@/global/constants';
import Cookie from '../../helpers/Cookie';

export default {
  name: 'Users',
  components: {PageTitle},
  data() {
    return {
      APP_URL,
      users: {
        data: [],
      },
      hasLoadUsers: false,

      filter: {
        // Filter
        email: '',
        role: '',
        active: '',
        name: '',
        officialRepresentative: false,
      },
      dates: [undefined, undefined],

      sort: {
        [`order[created_at]`]: 'desc',
      },

      columns: [
        {
          title: 'Email',
          // dataIndex: 'email',
          key: 'email',
          sorter: true,
          sortDirections: ['desc', 'asc'],
          scopedSlots: {customRender: 'email'},
        },
        {
          title: 'Имя',
          key: 'nickname',
          sorter: true,
          sortDirections: ['desc', 'desc'],
          scopedSlots: {customRender: 'name'},
        },
        {
          title: 'Роль',
          dataIndex: 'role',
          key: 'role',
          sorter: true,
          sortDirections: ['desc', 'asc'],
        },
        {
          title: 'Кол-во аппов',
          dataIndex: 'applications_count',
          key: 'applications_count',
        },
        {
          title: 'Дата регистрации',
          dataIndex: 'created_at',
          key: 'created_at',
          sorter: true,
          sortDirections: ['desc', 'asc'],
        },
        {
          title: 'Действия',
          dataIndex: '',
          scopedSlots: {customRender: 'operation'},
        },
      ],
    };
  },

  computed: {
    token() {
      return Cookie.get('admin_token');
    }
  },

  mounted() {
    this.getAllUsers();

  },

  methods: {
    resetFilter() {
      this.filter = {
        email: '',
        role: '',
        active: '',
        dates: [],
        name: '',
        page: 1,
        officialRepresentative: false,
      };
      this.dates = [undefined, undefined];
      this.sort = {
        [`order[created_at]`]: 'desc',
      };

      this.getAllUsers();
    },

    /**
     * @description Получение всех пользователей с пагинацией
     * @return {Promise<any>}
     */
    async getAllUsers(page = 1) {
      this.hasLoadUsers = true;

      await usersApi
          .searchUsers({...this.getFilterParams(), ...this.sort, page})
          .then((res) => res.json())
          .then((data) => {
            this.users = data;
            this.hasLoadUsers = false;
          });
    },

    /**
     * @param {string} field
     * @return {Promise<any>}
     */
    async sortUsersByField(field, order) {
      this.hasLoadUsers = true;
      this.sort = {[`order[${field}]`]: order};
      await usersApi
          .searchUsers({
            [`order[${field}]`]: order,
            ...this.getFilterParams(),
          })
          .then((res) => res.json())
          .then((data) => {
            this.hasLoadUsers = false;
            this.users = data;
          });
    },

    getFilterParams() {
      return {
        name: this.filter.name,
        email: this.filter.email,
        role: this.filter.role,
        active: this.filter.active,
        deletedAt: this.filter.active === '2' ? 1 : 0,
        officialRepresentative: this.filter.officialRepresentative ? 1 : undefined,
      };
    },

    onChange(pagination, filters, sorter) {
      const {order, columnKey} = sorter;
      this.sortUsersByField(columnKey, order);
    },

    async searchUsers() {
      this.hasLoadUsers = true;
      const [from, to] = this.dates;

      const params = {
        ...this.getFilterParams(),
        ['created_at[end]']: from && moment(from).format(DATE_FORMAT),
        ['created_at[start]']: to && moment(to).format(DATE_FORMAT),
      };

      await usersApi
          .searchUsers(params)
          .then((res) => res.json())
          .then((data) => {
            this.users = data;
            this.hasLoadUsers = false;
          });
    },

    /**
     * @description Пагинация по пользователям
     * @param {number} page
     * @return {void}
     */
    paginationChange(page) {
      this.getAllUsers(page);
      window.scrollTo(0, 0);
    },

    async deleteUser(user) {
      this.$confirm({
        title: `Вы действительно хотите удалить пользователя ${user.name} ${user.surname} ?`,
        onOk: async () => {
          const res = await users.deleteUserById(user.id);
          const json = await res.json();
          this.$message.success('Пользователь успешно удален :)');
          const index = this.users.data.findIndex((item) => item.id === user.id);
          // @ts-ignore
          this.users.data.splice(index, 1, json.data);
        },
      });
    },
  }
};
</script>

<style scoped lang="scss">
.ant-select {
  width: 100%;
}

.ant-row + .ant-row {
  margin-bottom: 10px;
}
</style>
