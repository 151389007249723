export const API_URL = process.env.VUE_APP_BACKEND_LOCATION;
export const APP_URL = String(process.env.VUE_APP_URL);
export const DATE_FORMAT = 'YYYY-MM-DD';

export const OS_SEO_NAME_WINDOWS = 'windows';
export const OS_SEO_NAME_MAC_OS = 'mac-os';
export const OS_SEO_NAME_LINUX = 'linux';

export const MODERATION_STATUS_ACCEPTED = 'accepted';
export const MODERATION_STATUS_REJECTED = 'rejected';
export const MODERATION_STATUS_NOT_MODERATED = 'not_moderated';

export const APPLICATION_UPDATE_LOG_STATUS_UPDATED = 'updated';
export const APPLICATION_UPDATE_LOG_STATUS_NO_UPDATES = 'no_updates';
export const APPLICATION_UPDATE_LOG_STATUS_ERROR = 'error';

export const OFFICIAL_REPRESENTATIVE_REQUEST_STATUS_PENDING = 'pending';
export const OFFICIAL_REPRESENTATIVE_REQUEST_STATUS_ACCEPTED = 'accepted';
export const OFFICIAL_REPRESENTATIVE_REQUEST_STATUS_DECLINED = 'declined';

export const LOCALE_RU = 'ru';
export const LOCALE_FR = 'fr';
export const LOCALE_EN = 'en';
export const LOCALE_EN_US = 'en-US';
export const LOCALE_FR_CI = 'fr-CI';
export const LOCALE_FR_CM = 'fr-CM';
export const LOCALE_FR_SN = 'fr-SN';
export const LOCALE_FR_FR = 'fr-FR';
export const LOCALE_EN_NG = 'en-NG';
export const LOCALE_EN_SO = 'en-SO';

export const LOCALE_LIST = [
  {
    title: 'Русский',
    locale: LOCALE_RU,
  },
  {
    title: 'Французский',
    locale: LOCALE_FR,
  },
  {
    title: 'Английский',
    locale: LOCALE_EN,
  },
];

export const LOCALE_LIST_EXTENDED = [
  {
    title: 'Русский',
    locale: LOCALE_RU,
  },
  {
    title: 'Французский',
    locale: LOCALE_FR,
  },
  {
    title: 'Французский (fr-FR)',
    locale: LOCALE_FR_FR,
  },
  {
    title: 'Французский (fr-CI)',
    locale: LOCALE_FR_CI,
  },
  {
    title: 'Французский (fr-CM)',
    locale: LOCALE_FR_CM,
  },
  {
    title: 'Французский (fr-SN)',
    locale: LOCALE_FR_SN,
  },
  {
    title: 'Английский (en-US, en)',
    locale: LOCALE_EN,
  },
  {
    title: 'Английский (en-NG)',
    locale: LOCALE_EN_NG,
  },
  {
    title: 'Английский (en-SO)',
    locale: LOCALE_EN_SO,
  },
];

export const LOCALE_DOMAIN_LIST = [
  {
    label: '.ru',
    value: LOCALE_RU,
  },
  {
    label: '.fr',
    value: LOCALE_FR_FR,
  },
  {
    label: '.ci',
    value: LOCALE_FR_CI,
  },
  {
    label: '.sn',
    value: LOCALE_FR_SN,
  },
  {
    label: '.net',
    value: LOCALE_EN,
  },
  {
    label: '.ng',
    value: LOCALE_EN_NG,
  },
  {
    label: '.so',
    value: LOCALE_EN_SO,
  },
];

export const LOCALE_TO_DOMAIN = {
  [LOCALE_RU]: '.ru',
  [LOCALE_FR_FR]: '.fr',
  [LOCALE_FR_CI]: '.ci',
  [LOCALE_FR_SN]: '.sn',
  [LOCALE_EN]: '.net',
  [LOCALE_EN_NG]: '.ng',
  [LOCALE_EN_SO]: '.so',
};

export const ARTICLE_STATUS_DRAFT = 'draft';
export const ARTICLE_STATUS_PUBLISHED = 'published';
export const ARTICLE_STATUS_AWAITING_MODERATION = 'awaiting_moderation';
export const ARTICLE_STATUS_REJECTED = 'rejected';
export const ARTICLE_STATUS_LABELS = {
  [ARTICLE_STATUS_DRAFT] : 'Черновик',
  [ARTICLE_STATUS_PUBLISHED] : 'Опубликован',
  [ARTICLE_STATUS_AWAITING_MODERATION] : 'Ожидает модерации',
  [ARTICLE_STATUS_REJECTED] : 'Отклонена',
}

export const NEWS_STATUS_DRAFT = 'draft';
export const NEWS_STATUS_PUBLISHED = 'published';
export const NEWS_STATUS_LABELS = {
  [NEWS_STATUS_DRAFT] : 'Черновик',
  [NEWS_STATUS_PUBLISHED] : 'Опубликован',
}

export const USER_BACK_SITE_URL_STATUS_AWAITING_VERIFICATION = 'awaiting_verification';

export const USER_BACK_SITE_URL_STATUS_FOUND = 'found';

export const USER_BACK_SITE_URL_STATUS_NOT_FOUND = 'not_found';