<template>
  <div>
    <PageTitle :title="title"/>
    <a-form :form="form" style="margin-top: 30px">
      <a-layout-content class="page__content">
        <a-row :gutter="24">
          <a-col span="12">
            <a-button size="large" type="primary" @click="handleSubmit">
              {{ action }}
            </a-button>
          </a-col>

        </a-row>
      </a-layout-content>

      <a-layout-content class="page__content">
        <a-spin :spinning="false">
          <div class="spin-content">
            <a-row :gutter="24">
              <a-col span="24">
                <a-form-item
                    label="Слаг (Пример: введенная строка `slug` создаст ссылку `mysite.com/page/slug`) "
                >
                  <a-input
                      size="large"
                      placeholder="Введите название (не более 150 символов)"
                      v-decorator="[
                      'slug',
                      {
                        initialValue: baseCategory.slug,
                        rules: [
                          {
                            required: false
                          }
                        ]
                      }
                    ]"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col span="24">
                <a-form-item label="Родительская категория ">
                  <a-select
                      size="large"
                      placeholder="Категория"
                      v-decorator="[
                      'parent_id',
                      {
                        initialValue: baseCategory.parent_id,
                        rules: [
                          {
                            required: false
                          }
                        ]
                      }
                    ]"
                  >
                    <a-select-option value="">Не выбрано</a-select-option>
                    <a-select-option
                        v-for="category in articlesCategories"
                        :key="category.id"
                        :value="String(category.id)"
                    >
                      {{ category.translations.ru.title }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="24" class="row-st">
              <a-card title="Локализация">
                <a-tabs
                    v-model="activeTab"
                    type="editable-card"
                    hide-add
                >
                  <template v-for="{locale, title} in LOCALE_LIST">
                    <a-tab-pane
                        :key="locale"
                        :tab="title"
                        :closable="false"
                    >
                      <a-row :gutter="24">
                        <a-col span="24">
                          <a-form-item :label="`Заголовок (${locale})`">
                            <a-input
                                size="large"
                                placeholder="Введите название (не более 150 символов)"
                                v-decorator="[
                            `translations.${locale}.title`,
                            {
                              initialValue: baseCategory.translations[locale] ? baseCategory.translations[locale].title : undefined,
                              rules: [
                                {
                                  required: true
                                }
                              ]
                            }
                          ]"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>

                      <a-row :gutter="24">
                        <a-col span="24">
                          <a-form-item :label="`Seo Title (${locale})`">
                            <a-input
                                size="large"
                                placeholder="Введите title"
                                v-decorator="[
                              `translations.${locale}.seo_title`,
                                {
                                  initialValue: baseCategory.translations[locale] ? baseCategory.translations[locale].seo_title : undefined,
                                  rules: [
                                    {
                                      required: false
                                    }
                                  ]
                                }
                            ]"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>

                      <a-row :gutter="24">
                        <a-col span="24">
                          <a-form-item :label="`Seo Description (${locale})`">
                            <a-textarea
                                style="height: 145px;"
                                size="large"
                                placeholder="Введите description"
                                v-decorator="[
                              `translations.${locale}.seo_description`,
                              {
                                initialValue: baseCategory.translations[locale] ? baseCategory.translations[locale].seo_description : undefined,
                                rules: [
                                  {
                                    required: false
                                  }
                                ]
                              }
                            ]"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-tab-pane>
                  </template>
                </a-tabs>
              </a-card>
            </a-row>

            <a-row :gutter="24">
              <a-col span="12">
                <a-button size="large" type="primary" @click="handleSubmit">Сохранить</a-button>
              </a-col>
            </a-row>
          </div>
        </a-spin>
      </a-layout-content>
    </a-form>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import {articles} from '@/api/articles';
import {LOCALE_LIST} from "@/global/constants";

export default {
  components: {
    PageTitle,
  },

  props: ['id'],

  mounted() {
    if (this.id) {
      this.hasLoadArticlesCategories = true;
      articles
          .getArticleCategoryById(this.id)
          .then((res) => res.json())
          .then(({data}) => {
            this.baseCategory = data;
            this.hasLoadArticlesCategories = false;
          });
    }

    this.getArticleCategories();
    window.scrollTo(0, 0);
  },

  data() {
    return {
      form: this.$form.createForm(this),
      articlesCategories: [],
      baseCategory: {
        translations: {
          ru: {
            title: '',
            seo_title: '',
            seo_description: '',
          },

          fr: {
            title: '',
            seo_title: '',
            seo_description: '',
          },

          en: {
            title: '',
            seo_title: '',
            seo_description: '',
          },
        },
      },
      hasLoadArticlesCategories: false,
      LOCALE_LIST,
      activeTab: 'ru',
    };
  },

  computed: {
    title() {
      return this.id ? 'Редактировать категорию' : 'Создать категорию';
    },

    action() {
      return this.id ? 'Сохранить' : 'Создать';
    },
  },

  created() {

  },

  methods: {
    async handleSubmit() {
      this.form.validateFieldsAndScroll(async (errors, values) => {
        if (null === errors) {
          if (this.id) {
            try {
              const response = await articles.updateCategoryArticle(this.id, values);
              const json = await response.json();

              this.$message.success('Успешно отредактировано!');
            } catch (error) {
              if (error.status === 422) {
                const json = await error.json();
                this.$message.error(json.message);
              }
            }
          } else {
            try {
              const response = await articles.createCategoryArticle(values);
              const json = await response.json();
              this.$message.success('Успешно создано!');
              this.$router.push({path: `/articles/category/${json.data.id}/edit`});
            } catch (error) {
              console.log(error);
              if (error.status === 422) {
                const json = await error.json();
                this.$message.error(json.message);
              }
            }
          }
        }
      });
    },

    async getArticleCategories() {
      this.hasLoadArticlesCategories = true;
      await articles
          .getArticleCategory()
          .then((res) => res.json())
          .then(({data}) => {
            this.articlesCategories = data;
            this.hasLoadArticlesCategories = false;
          });
    },
  },
};
</script>

<style scoped lang="scss">
.ant-menu-root {
  height: 80px !important;
}

.ant-menu-item {
  padding: 0 100px;
  font-size: 16px;
}
</style>
