<template>
  <div>
    <PageTitle title="Новости">
      <router-link to="/news/add">
        <a-button size="large" type="primary" icon="plus">Добавить новость</a-button>
      </router-link>
    </PageTitle>
    <a-layout-content class="page__content">
      <a-row>
        <h3>Поиск</h3>
      </a-row>
      <a-row :gutter="24">
        <a-col span="5">
          <a-input
            size="large"
            v-model="search.title"
            placeholder="Название"
            v-on:keyup.enter="handleSubmit"
          ></a-input>
        </a-col>
        <a-col :span="5">
          <a-form-item>
            <a-select
              placeholder="Выберите статус"
              size="large"
              v-model="search.status"
            >
              <a-select-option :value="null" key="0">Все</a-select-option>
              <a-select-option value="deleted" key="1">В корзине</a-select-option>
              <a-select-option value="published" key="2">Опубликовано</a-select-option>
              <a-select-option value="draft" key="3">Черновик</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="5">
          <a-select
            placeholder="Автор"
            showSearch
            :showArrow="false"
            size="large"
            @search="searchDevelopers"
            notFoundContent="Введите имя разработчика..."
            v-model="search.author_id"
            :value="value"
            style="width: 100%"
            :filterOption="false"
          >
            <a-select-option
              v-for="developer in developersList"
              :key="developer.id"
              :value="developer.id"
              >{{ developer.name }}</a-select-option
            >
          </a-select>
        </a-col>

        <a-col span="8">
          <a-range-picker
            :placeholder="['Начало', 'Конец']"
            size="large"
            v-model="dates"
          ></a-range-picker>
        </a-col>
        <a-col span="24" style="margin-top: 25px">
          <a-button
            size="large"
            type="primary"
            icon="search"
            @click="handleSubmit"
            style="margin-right: 25px"
            >Поиск</a-button>
          <a-button size="large" @click="resetFilter">Сбросить</a-button>
        </a-col>
      </a-row>
    </a-layout-content>

    <a-layout-content class="page__content">
      <a-table
        :columns="columns"
        :pagination="false"
        :dataSource="news.data"
        :loading="hasLoadNews"
        rowKey="id"
        @change="onChange"
      >
        <template slot="categories" slot-scope="categories, record">
          <a-tag
            v-if="categories.data"
            v-for="category in categories.data"
            :key="category.id"
            color="blue"
          >
            {{ category.translations.ru.title }}
          </a-tag>
        </template>

        <template #status="status, record">
          <template v-if="record.deleted_at">
            <a-tag color="red">
              В корзине
            </a-tag>
          </template>
          <template v-else-if="status === NEWS_STATUS_DRAFT">
            <a-tag color="orange">
              {{ NEWS_STATUS_LABELS[NEWS_STATUS_DRAFT] }}
            </a-tag>
          </template>
          <template v-else-if="status === NEWS_STATUS_PUBLISHED">
            <a-tag color="green">
              {{ NEWS_STATUS_LABELS[NEWS_STATUS_PUBLISHED] }}
            </a-tag>
          </template>
        </template>

        <template slot="actions" slot-scope="id, record">
          <a :href="record.urlNews" target="_blank">
            <a-button
              type="primary"
              class="gray-btn"
              icon="eye"
              style="margin-right: 10px"
            ></a-button>
          </a>

          <template v-if="record.deleted_at">
            <a-button
              type="dashed"
              class="gray-btn"
              icon="undo"
              style="margin-right: 10px"
              @click="restore(record.id)"
            />
          </template>

          <template v-else>
            <router-link :to="{ path: `/news/${record.id}/edit` }">
              <a-button
                type="primary"
                class="gray-btn"
                icon="edit"
                style="margin-right: 10px"
              />
            </router-link>

            <a-button
              type="primary"
              class="gray-btn"
              icon="delete"
              style="margin-right: 10px"
              @click="deleteNews(record.id)"
            />
          </template>
        </template>

        <template slot="author" slot-scope="author">
          {{ author.name }}
        </template>

        <template v-slot:name="translations">
          {{ translations?.ru?.title ?? translations[Object.keys(translations)[0]].title }}
        </template>
      </a-table>

      <a-row style="text-align: right;">
        <a-pagination
          v-if="news.meta"
          @change="paginationChange"
          v-model="news.meta.current_page"
          :total="news.meta.total"
          :pageSize="news.meta.per_page"
          style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import {news, restoreNews} from '@/api/news';
import { debounce } from 'lodash';
import { users } from '@/api/users';
import {NEWS_STATUS_DRAFT, NEWS_STATUS_LABELS, NEWS_STATUS_PUBLISHED} from "@/global/constants";

export default {
  name: 'NewsList',
  components: { PageTitle },
  data() {
    return {
      searchDevelopers: debounce(this.handleDevelopersSearch, 300),
      developersList: [],
      news: {},
      dates: [],
      search: {
        title: undefined,
        author_id: undefined,
        status: null,
        page: 1,
        sortField: 'publishedAt',
        sortOrder: 'desc',
      },
      hasLoadNews: false,
      columns: [
        {
          title: 'Название',
          dataIndex: 'translations',
          key: 'translations',
          width: 480,
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Автор',
          dataIndex: 'author',
          key: 'author',
          scopedSlots: { customRender: 'author' },
          sorter: true,
          sortDirections: ['desc', 'asc'],
        },
        {
          title: 'Категория',
          dataIndex: 'categories',
          key: 'categories',
          scopedSlots: { customRender: 'categories' },
        },
        {
          title: 'Статус',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Дата публикации',
          dataIndex: 'published_at',
          key: 'date',
          sorter: true,
          sortDirections: ['desc', 'asc'],
        },
        {
          title: 'Действия',
          dataIndex: '',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
        },
      ],
      NEWS_STATUS_DRAFT,
      NEWS_STATUS_PUBLISHED,
      NEWS_STATUS_LABELS
    };
  },

  mounted() {
    this.handleSubmit();
  },

  methods: {
    onChange(pagination, filters, sorter) {
      const { order, field } = sorter;
      this.search.sortField = field;
      this.search.sortOrder = order;
      this.handleSubmit(1);
    },

    resetFilter() {
      this.search = {
        title: undefined,
        author_id: undefined,
        status: null,
        page: 1,
        sortField: 'publishedAt',
        sortOrder: 'desc',
      };
      this.dates = [];
      this.handleSubmit();
    },

    async handleSubmit(page = 1) {
      this.hasLoadNews = true;
      const { title, author_id } = this.search;
      const [dateStart, dateEnd] = this.dates;

      const params = {
        title,
        author_id,
        page,
        publishedAt: {
          start: dateStart && dateStart.format('YYYY-MM-DD'),
          end: dateEnd && dateEnd.format('YYYY-MM-DD'),
        },
        [`order[${this.search.sortField}]`] : this.search.sortOrder,
      };

      if (this.search.status === 'deleted') {
        params.deleted_at = 1;
      } else {
        params.status = this.search.status;
        params.deleted_at = 0;
      }

      await news
        .searchNews(params)
        .then((res) => res.json())
        .then((data) => {
          this.news = data;
          this.hasLoadNews = false;
        });
    },

    async handleDevelopersSearch(value) {
      await users
        .searchUsers({
          name: value,
        })
        .then((res) => res.json())
        .then(({ data }) => {
          this.developersList = data;
        });
    },

    deleteNews(id) {
      this.$confirm({
        title: `Вы действительно хотите удалить данную новость?`,
        content: 'Вы сможете восстановить ее после удаления.',
        onOk: async () => {
          await news.deleteNews(id).then(() => {
            this.$message.success('Новость успешна удалена', 2);
            this.handleSubmit();
          });
        },
      });
    },

    paginationChange(page) {
      window.scrollTo(0, 0);
      this.handleSubmit(page);
    },

    restore(id) {
      this.$confirm({
        title: `Вы действительно хотите восстановить данную новость?`,
        onOk: async () => {
          await restoreNews(id).then(() => {
            this.$message.success('Новость успешна восстановлена', 3);
            this.handleSubmit();
          });
        },
      });
    },
  },
};
</script>

<style lang="scss"></style>
