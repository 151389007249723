<template>
  <div>
    <AddPageTitle
      :title="title"
      :id="id || moderationId"
    />
    <ModerationTop v-if="moderationId" :appId="moderationId"/>
    <DeveloperModeration v-if="id && !hasLoadUser" :appId="id"/>
    <a-layout-content class="page__content">
      <TopMenu :id="id" :moderationId="moderationId" active="description"/>
      <a-spin :spinning="hasLoadApplication">
        <div class="spin-content">
          <a-form :form="form" style="margin-top: 30px">
            <a-row :gutter="24">
              <a-col span="12">
                <a-form-item label="Платформа">
                  <a-select
                    placeholder="Выберите платформу"
                    size="large"
                    v-decorator="[
                      'platformTypeId',
                      {
                        initialValue: app.platform && app.platform.id,
                        rules: [
                          {
                            required: true,
                            message: 'Платформа - обязательное поле'
                          }
                        ]
                      }
                    ]"
                  >
                    <a-select-option
                      v-for="platform in platformTypes"
                      :value="platform.id"
                      :key="platform.id"
                    >{{ platform.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col span="12">
                <a-form-item label="Операционная система">
                  <a-select
                    placeholder="Выберите операционную систему"
                    @change="handleOsChange"
                    size="large"
                    v-decorator="[
                      'operationSystemId',
                      {
                        initialValue:
                          app.operationSystem && app.operationSystem.id,
                        rules: [
                          {
                            required: true,
                            message: 'Операционная система - обязательное поле'
                          }
                        ]
                      }
                    ]"
                  >
                    <a-select-option
                      v-for="os in operationSystems"
                      :value="os.id"
                      :key="os.id"
                    >{{ os.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col span="24">
                <a-form-item label="Версия операционной системы">
                  <a-select
                    mode="tags"
                    placeholder="Выберите версию операционной системы"
                    size="large"
                    v-decorator="[
                      'osVersions',
                      {
                        initialValue:
                          app.osVersions &&
                          app.osVersions.map(version => String(version.id)),
                        rules: [
                          {
                            required: true,
                            message:
                              'Версия операционной системы - обязательное поле'
                          }
                        ]
                      }
                    ]"
                  >
                    <a-select-option
                      v-for="version in operationSystemsVersionsByOs"
                      :value="String(version.id)"
                      :key="version.id"
                    >{{ version.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col span="8">
                <a-form-item label="Лицензия">
                  <a-select
                    placeholder="Выберите лицензию"
                    size="large"
                    v-decorator="[
                      'licenseTypeId',
                      {
                        initialValue: app.license && app.license.id,
                        rules: [
                          {
                            required: true,
                            message: 'Лицензия - обязательное поле'
                          }
                        ]
                      }
                    ]"
                  >
                    <a-select-option
                      v-for="license in licenseTypes"
                      :value="license.id"
                      :key="license.id"
                    >{{ license.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col span="8">
                <a-form-item label="Сайт">
                  <a-input
                    placeholder="Введите сайт"
                    size="large"
                    v-decorator="[
                      'site',
                      {
                        initialValue: app.site,
                        rules: [
                          {
                            required: false
                          }
                        ]
                      }
                    ]"
                  ></a-input>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24" class="row-st">
              <a-col lg="24">
                <a-form-item label="Языки интерфейса">
                  <a-checkbox-group
                    :options="options"
                    v-decorator="[
                      'interfaceLangs',
                      {
                        initialValue:
                          app.interfaceLangs &&
                          app.interfaceLangs.data &&
                          app.interfaceLangs.data.map(app => app.id),
                        rules: [
                          {
                            required: true,
                            message: 'Выберите хотя бы один язык'
                          }
                        ]
                      }
                    ]"
                  ></a-checkbox-group>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="24" class="row-st">

              <a-card>
                <a-row :gutter="24">
                  <a-col span="12">
                    <a-form-item :label="`Название (ru)`">
                      <a-input
                        size="large"
                        placeholder="Введите название (не более 150 символов)"
                        v-decorator="[
                            `translations.ru.name`,
                            {
                        initialValue: app.translations.ru.name,
                        rules: [
                          {
                            required: true,
                            max: 150,
                            message: 'Название обязательное поле. Должно быть не более 150 символов'
                          }
                        ]
                      }
                    ]"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col span="8">
                    <a-form-item label="Цена">
                      <a-input
                        placeholder="Цена"
                        size="large"
                        v-decorator="[
                      `translations.ru.price`,
                      {
                        initialValue: app.translations.ru.price,
                        rules: [
                          {
                            required: false
                          }
                        ]
                      }
                    ]"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>


                <a-form-item
                  :label="`Краткое описание (ru)`"
                  :class="{'has-error': errorsBag.shortDescriptionRu}"
                >
                  <a-textarea
                    auto-size
                    v-decorator="[
                        `translations.ru.short_description`,
                        {
                          initialValue: app.translations.ru.short_description,
                          rules: [
                            {
                              required: true,
                              min: 100,
                              max: 500,
                              message: 'Краткое описание обязательное поле. Должно быть не менее 100 и не более 500 символов',
                            }
                          ],
                        }
                        ]"
                  />
                  <p>
                    Количество слов:
                    {{ getWordCount(form.getFieldValue(`translations.ru.short_description`)) }}
                    Количество символов:
                    {{
                      form.getFieldValue(`translations.ru.short_description`) ? String(form.getFieldValue(`translations.ru.short_description`)).length : 0
                    }}
                  </p>
                </a-form-item>

                <a-form-item
                  :label="`Полное описание (ru)`"
                  :class="{'has-error': errorsBag.descriptionRu}"
                >
                  <ckeditor
                    v-decorator="[
                        `translations.ru.description`, {
                        initialValue: app.translations.ru.description,
                        rules: [
                            {
                              required: true,
                              min: 250,
                              max: 50000,
                              message: 'Полное описание обязательное поле. Должно быть не менее 250 и не более 50000 символов',
                            }
                          ],
                      }
                      ]"
                    @input="form.setFieldsValue({[`translations.ru.description`] : $event})"
                    :config="editorConfig()"
                  />

                  <p>
                    Количество слов: {{ getWordCount(app.translations.ru.description) }}
                    Количество символов:
                    {{
                      form.getFieldValue(`translations.ru.description`) ? String(form.getFieldValue(`translations.ru.description`)).length : 0
                    }}
                  </p>
                </a-form-item>
              </a-card>
            </a-row>

            <a-row gutter="24">
              <a-col span="8">
                <a-form-item label="Опубликовано">
                  <a-switch
                    v-decorator="[
                      'enabled',
                      {
                        valuePropName: 'checked',
                        initialValue: app.enabled,
                        rules: [
                          {
                            required: false
                          }
                        ]
                      }
                    ]"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col span="24" style="text-align: right">
                <a-button size="large" type="primary" @click="handleSaveDescription">{{ action }}</a-button>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-spin>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import ModerationTop from '@/components/Moderation/Applications/ModerationTop.vue';
import TopMenu from './TopMenu.vue';
import { mapState } from 'vuex';
import { isEmpty, merge } from 'lodash';
import { applications } from '@/api';
import DeveloperModeration from '@/components/Moderation/Applications/DeveloperModeration.vue';
import AddPageTitle from '@/components/Applications/Add/AddPageTitle.vue';
import { getCkeditorConfig } from '@/helpers/ckeditor';

export default {
  props     : ['id', 'moderationId'],
  components: {
    AddPageTitle,
    PageTitle,
    TopMenu,
    ModerationTop,
    DeveloperModeration,
  },

  computed: mapState([
    'operationSystems',
    'platformTypes',
    'licenseTypes',
    'operationSystemsVersions',
    'languages',
    'user',
    'hasLoadUser',
  ]),

  created() {
    if (this.user.hasDeveloper && !Boolean(this.user.name)) {
      this.$message.error('Заполните имя разработчика');
      this.$router.push({ name: 'Profile' });
    }
  },

  mounted() {
    if (this.moderationId) {
      this.title  = 'Модерация приложения';
      this.action = 'Сохранить';
      this.getApplicationById();
    } else if (this.id) {
      this.title  = 'Редактировать приложение';
      this.action = 'Сохранить';
      this.getApplicationById();
    } else {
      this.title  = 'Создать приложение';
      this.action = 'Создать';
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === 'SET_OPERATION_SYSTEMS_VERSIONS') {
        this.operationSystemsVersionsByOs = this.$store.state.operationSystemsVersions.filter(
          (version) =>
            version.operationSystem.id === this.app.operationSystem.id,
        );
      }

      if (mutation.type === 'SET_LANGUAGES_LIST') {
        this.options = this.languages.map((language) => ({
          label: language.name,
          value: language.id,
        }));
      }
    });

    this.options = this.languages.map((language) => ({
      label: language.name,
      value: language.id,
    }));

    window.scrollTo(0, 0);
  },

  data() {
    return {
      app                         : {
        id                    : 0,
        name                  : undefined,
        seoName               : undefined,
        platform              : undefined,
        operationSystem       : undefined,
        osVersions            : [],
        site                  : undefined,
        developer             : undefined,
        groupNames            : undefined,
        relatedApplications   : undefined,
        bzFutureApplication   : undefined,
        articleRelations      : undefined,
        license               : undefined,
        interfaceLangs        : undefined,
        frequency             : undefined,
        isLastUploaded        : undefined,
        isApiOffer            : undefined,
        isApi                 : undefined,
        isPopular             : undefined,
        enabled               : undefined,
        linkShow              : undefined,
        disable_download_modal: undefined,
        update_url            : undefined,
        benefits              : [],
        enable_qa_block       : undefined,
        enable_benefits_block : undefined,
        questions             : [],
        enable_pros_cons_block: undefined,
        enable_autoupdate     : undefined,
        google_play_id        : undefined,
        translations          : {
          ru: {
            description      : '',
            short_description: '',
            name             : '',
            price            : '',
            pros             : '',
            cons             : '',
          },
        },
      },
      title                       : '',
      action                      : '',
      hasLoadApplication          : false,
      editorConfig                : getCkeditorConfig,
      form                        : this.$form.createForm(this),
      options                     : undefined,
      operationSystemsVersionsByOs: [],

      errorsBag: {},
    };
  },

  methods: {
    getWordCount(text) {
      if (!text) {
        return 0;
      }

      return text
        .replace(/<(?:.|\n)*?>/gm, '')
        .replace(/(\r\n|\n|\r)/gm, '')
        .replace('&nbsp;', '')
        .trim().length;
    },

    /**
     * @description Обработчик на выбор os
     * @return {void}
     */
    handleOsChange(osId) {
      this.operationSystemsVersionsByOs = this.$store.state.operationSystemsVersions.filter(
        (version) => version.operationSystem.id === osId,
      );
      this.form.resetFields(['osVersions']);
      this.app.osVersions = [];
    },

    /**
     * @description Получение приложения по id
     * @return {Promise<any>}
     */
    async getApplicationById() {
      this.hasLoadApplication = true;
      await applications
        .getAppById(this.id || this.moderationId)
        .then((res) => res.json())
        .then(({ data }) => {
          const { operationSystem: { id: osId } } = data;

          const osVersions = this.$store.state.operationSystemsVersions;

          if (osVersions.length) {
            this.operationSystemsVersionsByOs = osVersions.filter(
              (version) => version.operationSystem.id === osId,
            );
          }

          this.hasLoadApplication = false;

          this.app = merge(this.app, data);

          return data;
        });
    },

    /**
     * @description Обработка ошибок
     * @param {string} message
     * @return {function}
     */
    handleErrors(message) {
      return ({ errors, code, data }) => {
        if (errors && code === 422) {
          Object.values(errors).forEach((values) => {
            values.forEach((value) => {
              this.$message.error(value);
            });
          });
        } else {
          this.$message.success(message, 2);
        }
        return data;
      };
    },

    /**
     * @description Сохранение описания
     * @return {Promise<any>}
     */
    async handleSaveDescription() {
      this.form.validateFieldsAndScroll(
        async (errors, values) => {
          if (isEmpty(errors)) {
            const params = { ...values };

            Object.keys(params).forEach((key) => {
              const value = params[key];

              if (value instanceof Array) {
                params[key] = value.map((val) => Number(val));
              }

              if (typeof value === 'boolean') {
                params[key] = Number(value);
              }
            });

            // Если роль - разработчик
            if (this.user.hasDeveloper && !this.id) {
              await applications
                .createAppDescription({...params, developerId: this.user.id})
                .then((res) => res.json())
                .then(
                  this.handleErrors(
                    'Описание успешно создано, добавьте файлы и скриншоты :)',
                  ),
                )
                .then(({ id }) => {
                  window.scrollTo(0, 0);
                  if (id) {
                    this.$router.push(`/add-files/${id}/edit`);
                  }
                });
              return;
            }

            // Если есть id приложения или moderation Id
            if (this.id || this.moderationId) {
              if (this.user.hasDeveloper) {
                params.developerId = this.user.id;
              }

              await applications
                .updateAppDescription(
                  this.id || this.moderationId,
                  { ...params },
                )
                .then((res) => res.json())
                .then(this.handleErrors('Описание успешно отредактировано :)'))
                .then((data) => {
                  window.scrollTo(0, 0);

                  Object.assign(this.app, data);

                  if (this.moderationId) {
                    this.$router.push(`/moderation/${this.moderationId}/files`);
                  }
                });
            }
          }
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
</style>
