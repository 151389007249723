<template>
  <div>
    <PageTitle title="Редактирование комментария"/>

    <a-layout-content class="page__content">
      <a-spin tip="Loading..." :spinning="loading"/>

      <template v-if="!loading">
        <div class="change__comment__info">
          <b>Пользователь: {{ comment.user && comment.user.name }}</b>
        </div>
        <div class="change__comment__info">
          <b>Дата создания: </b>{{ comment.created_at }}
        </div>
        <div class="change__comment__info">
          <b>Дата публикации: </b>{{ comment.published_at }}
        </div>
        <div class="change__comment__info" v-if="commentId">
          <b>Оценка: </b>{{ comment.rating }}
        </div>

        <div class="change__comment__info">
          <b>Локаль: </b>{{ comment.locale }}
        </div>

        <a-textarea v-model="comment.text" style="height: 120px"/>

        <a-button
            type="primary"
            size="large"
            @click="handleSubmit"
        >
          Сохранить
        </a-button>

        <a-button
            v-if="comment.mark_as_spam"
            size="large"
            class="orange-btn"
            type="primary"
            @click="removeCommentFromSpam"
        >
          Убрать из спама
        </a-button>

        <a-button
            v-else
            size="large"
            class="orange-btn"
            @click="seCommentToSpam"
        >
          Отметить как спам
        </a-button>
      </template>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import {commentsApps} from '@/components/Comments/routes';
import {applications} from '@/api';
import {merge} from 'lodash';

export default {
  name: 'CommentsAppsChange',

  components: {
    PageTitle,
  },

  props: ['commentId'],

  data() {
    return {
      comment: {
        mark_as_spam: 0,
        text: '',
      },
      loading: true,
    };
  },

  mounted() {
    this.getAppReviewById();
  },

  methods: {
    async getAppReviewById() {
      this.loading = true;

      const response = await applications.getCommentById(this.commentId);
      const {data} = await response.json();

      merge(this.comment, data);

      this.loading = false;
    },

    async seCommentToSpam() {
      await applications
          .updateCommentSpam(this.commentId, {
            markAsSpam: 1,
          })
          .then(async (res) => {
            if (res.status === 200) {
              this.$message.success('Отзыв помещен в спам', 2);
              await this.getAppReviewById();
            }
          });
    },

    async removeCommentFromSpam() {

      await applications
          .updateCommentSpam(this.commentId, {
            markAsSpam: 0,
          })
          .then(async (res) => {
            if (res.status === 200) {
              this.$message.success('Отзыв убран из спама :)', 2);
              await this.getAppReviewById();
            }
          });

    },

    async editCommentById() {
      const params = {...this.comment};

      delete params.markAsSpam;
      await applications
          .updateCommentById(this.commentId, params)
          .then((res) => {
            if (res.status === 200) {
              this.$message.success('Успешно обновлено :)');
              this.$router.push(commentsApps);
            }
          });
    },

    async handleSubmit() {
      await this.editCommentById();
    },
  },

};
</script>

<style lang="scss" scoped>
.change__comment__info {
  margin: 15px 0;
  font-size: 14px;
}

.page__content {
  position: relative;
}

.orange-btn,
.orange-btn:active,
.orange-btn:focus,
.orange-btn:hover {
  background: #ffd666;
  border-radius: 4px;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  font-size: 14px;
}
</style>
