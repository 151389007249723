import Moderation from './Applications/Moderation.vue';
import Description from '@/components/Applications/Add/Description.vue';
import Files from '@/components/Applications/Add/Files.vue';
import Images from '@/components/Applications/Add/Images.vue';
import Seo from '@/components/Applications/Add/Seo.vue';
import Chat from './Applications/Chat.vue';
import ArticlesList from '../Articles/ArticlesList';
import {ARTICLE_STATUS_AWAITING_MODERATION} from "@/global/constants";

export const moderationRoute = '/moderation';
export const moderationDescriptionRoute = '/moderation/:moderationId/description';
export const moderationFilesRoute = '/moderation/:moderationId/files';
export const moderationImagesRoute = '/moderation/:moderationId/images';
export const moderationSeoRoute = '/moderation/:moderationId/seo';
export const declineRoute = '/moderation/:appId/decline';
export const sendModerationRoute = '/developer-moderation/:appId/send';

export const moderationRoutes = [
  {
    path: moderationRoute,
    component: Moderation,
    name: 'Модерация',
    breadcrumbName: 'Модерация',
    meta: {
      title: 'Модерация',
    },
  },
  {
    component: Description,
    path: moderationDescriptionRoute,
    props: true,
    name: 'Модерация (редактирование)',
    breadcrumbName: 'Модерация (редактирование)',
    meta: {
      title: 'Модерация (редактирование)',
    },
  },
  {
    component: Files,
    path: moderationFilesRoute,
    props: true,
    name: 'Модерация (файлы)',
    breadcrumbName: 'Модерация (файлы)',
    meta: {
      title: 'Модерация (файлы)',
    },
  },
  {
    component: Images,
    path: moderationImagesRoute,
    props: true,
    name: 'Модерация (изображения)',
    breadcrumbName: 'Модерация (изображения)',
    meta: {
      title: 'Модерация (изображения)',
    },
  },
  {
    component: Seo,
    path: moderationSeoRoute,
    props: true,
    name: 'Модерация (seo)',
    meta: {
      title: 'Модерация (seo)',
    },
  },
  {
    component: Chat,
    path: declineRoute,
    props: true,
    name: 'Отклонить приложение',
    breadcrumbName: 'Отклонить приложение',
    meta: {
      title: 'Отклонить приложение',
    },
  },
  {
    component: Chat,
    path: sendModerationRoute,
    props: true,
    name: 'Модерация (chat)',
    breadcrumbName: 'Модерация',
    meta: {
      title: 'Модерация (chat)',
    },
  },

  {
    path: '/moderation/articles',
    component: ArticlesList,
    name: 'articles-moderation',
    props: {
      status: ARTICLE_STATUS_AWAITING_MODERATION,
    },
  },
];

export default {moderationRoutes};
