var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-form', [_c('a-row', {
    attrs: {
      "gutter": "24"
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Название* "
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.baseOs.label,
      callback: function callback($$v) {
        _vm.$set(_vm.baseOs, "label", $$v);
      },
      expression: "baseOs.label"
    }
  })], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": "24"
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Уникальное название"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.baseOs.name,
      callback: function callback($$v) {
        _vm.$set(_vm.baseOs, "name", $$v);
      },
      expression: "baseOs.name"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Seo название"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.baseOs.seo_name,
      callback: function callback($$v) {
        _vm.$set(_vm.baseOs, "seo_name", $$v);
      },
      expression: "baseOs.seo_name"
    }
  })], 1)], 1)], 1), _c('a-row', [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Тип платформы"
    }
  }, [_c('a-select', {
    attrs: {
      "placeholder": "Тип платформы",
      "size": "large"
    },
    model: {
      value: _vm.baseOs.platform_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.baseOs, "platform_type_id", $$v);
      },
      expression: "baseOs.platform_type_id"
    }
  }, _vm._l(_vm.platformTypes, function (platform) {
    return _c('a-select-option', {
      key: platform.id,
      attrs: {
        "value": platform.id
      }
    }, [_vm._v(" " + _vm._s(platform.name) + " ")]);
  }), 1)], 1)], 1), _c('a-col', {
    staticStyle: {
      "margin-top": "40px",
      "margin-left": "20px"
    },
    attrs: {
      "span": "4"
    }
  }, [_c('a-switch', {
    model: {
      value: _vm.baseOs.is_mobile,
      callback: function callback($$v) {
        _vm.$set(_vm.baseOs, "is_mobile", $$v);
      },
      expression: "baseOs.is_mobile"
    }
  }), _vm._v(" Мобильная платформа ")], 1), _c('a-col', {
    staticStyle: {
      "margin-top": "40px"
    },
    attrs: {
      "span": "4"
    }
  }, [_c('a-switch', {
    model: {
      value: _vm.baseOs.enabled,
      callback: function callback($$v) {
        _vm.$set(_vm.baseOs, "enabled", $$v);
      },
      expression: "baseOs.enabled"
    }
  }), _vm._v(" Вкл/Выкл ")], 1)], 1), _c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(_vm._s(_vm.action))])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }