
// @ts-nocheck
import Vue from 'vue';
import moment from 'moment';
import {users} from '@/api/users';
import {
  DATE_FORMAT,
  USER_BACK_SITE_URL_STATUS_AWAITING_VERIFICATION,
  USER_BACK_SITE_URL_STATUS_FOUND, USER_BACK_SITE_URL_STATUS_NOT_FOUND
} from '@/global/constants';
import PageTitle from '@/components/Global/PageTitle.vue';

export default Vue.extend({
  props: ['id'],

  components: {PageTitle},

  data() {
    return {
      loadUser: false,
      password: '',
      action: '',
      title: '',
      user: {
        email: '',
        name: '',
        contact: '',
        emailVerified: 1,
        role: 'user',
        description: '',
        site: '',
        official_representative: false,
        active: true,
        deleted_at: null,
        back_site_url_status: null,
      },
      formLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 14},
      },
      USER_BACK_SITE_URL_STATUS_AWAITING_VERIFICATION,
      USER_BACK_SITE_URL_STATUS_FOUND,
      USER_BACK_SITE_URL_STATUS_NOT_FOUND,
    };
  },

  methods: {
    async setUserToBan() {
      await users
        .updateUser(this.id, {...this.user, active: 0})
        .then((res) => res.json())
        .then((json) => {
          this.user = json.data;
          this.$message.success('Пользователь успешно добавлен в бан.');
        });
    },

    setRandomPassword() {
      this.password = Math.random()
        .toString(36)
        .slice(-8);
    },

    async deleteUser(): Promise<any> {
      this.$confirm({
        title: `Вы действительно хотите удалить пользователя ${this.user.name}?`,
        onOk: async () => {
          const res = await users.deleteUserById(this.id);
          const json = await res.json();
          this.$message.success('Пользователь успешно удален :)');
          this.user = json.data;
        },
      });
    },

    async restoreUser(): Promise<any> {
      this.$confirm({
        title: `Вы действительно хотите восстановить пользователя ${this.user.name}?`,
        onOk: async () => {
          const res = await users.restoreUserById(this.id);
          const json = await res.json();
          this.$message.success('Пользователь успешно восстановлен :)');
          this.user = json.data;
        },
      });
    },

    async getUserById(): Promise<any> {
      this.loadUser = true;
      await users
        .getUserById(this.id)
        .then((res) => res.json())
        .then(({data}) => {
          this.user = data;
          this.loadUser = false;
        });
    },

    handleErrors(message: string) {
      return ({errors}: any) => {
        if (errors) {
          Object.values(errors).forEach((values: any) => {
            values.forEach((value: string) => {
              this.$message.error(value);
            });
          });
        } else {
          this.$message.success(message, 2);
        }
      };
    },

    saveChanges() {
      this.updateUserById();
    },

    async updateUserById(): Promise<any> {
      if (this.user.birthday) {
        this.user.birthday = moment(this.user.birthday).format(DATE_FORMAT);
      }

      if (this.id) {
        await users
          .updateUser(this.id, {
            ...this.user,
            password: this.password.length ? this.password : undefined,
          })
          .then((res) => res.json())
          .then(this.handleErrors('Пользователь успешно обновлен :)'));
      } else {
        await users
          .createUser({...this.user, password: this.password.length ? this.password : undefined})
          .catch(async (response) => {
            const json = await response.json();
            const fnErrors = this.handleErrors('Пользователь успешно создан :)');
            fnErrors(json);
          })
          .then((res) => res.json())
          .then(json => {
            this.$router.push({name: 'user-edit', params: {id: json.data.id}});
          });
      }
    },
  },

  created() {
    if (this.id) {
      this.getUserById();
      this.action = 'Сохранить';
      this.title = 'Редактировать пользователя';
    } else {
      this.action = 'Создать';
      this.title = 'Создать пользователя';
    }
  },
});
