
// @ts-nocheck
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import { staticPages } from '@/api/pages';
import { staticPagesRoute } from '@/components/Pages/routes';
import { pickBy } from 'lodash';
import {getCkeditorConfig} from '@/helpers/ckeditor';

export default Vue.extend({
  components: {
    PageTitle,
  },
  props: ['id'],
  data() {
    return {
      title: '',
      action: '',
      form: this.$form.createForm(this),
      page: {
        en: {
          title: '',
          seo_title: '',
          seo_description: '',
          locale: 'en',
          content: '',
        },
        ru: {
          title: '',
          seo_title: '',
          seo_description: '',
          locale: 'ru',
          content: '',
        },
        slug: '',
        status: false,
      },
    };
  },

  mounted() {
    if (this.id) {
      this.title = 'Редактировать статью';
      this.action = 'Сохранить';
      this.getStaticPageById();
    } else {
      this.title = 'Создать статью';
      this.action = 'Создать';
    }
  },

  methods: {
    getCkeditorConfig,

    handleSubmit() {
      this.form.validateFieldsAndScroll(
        async (errors: any, values: any): Promise<any> => {
          if (!errors) {
            const params = {
              ...values.page,
              en: {
                content: this.page.en && this.page.en.content,
                ...values.page.en,
              },
              ru: {
                content: this.page.ru && this.page.ru.content,
                ...values.page.ru,
              },
              slug: values.slug,
              status: Number(values.status),
            };

            params.en = pickBy(params.en);
            params.ru = pickBy(params.ru);

            if (this.id) {
              await staticPages
                .updateStaticPage(this.id, params)
                .then((res) => res.json())
                .then(() => {
                  this.$message.success(
                    'Статичная страница успешно обновлена :)',
                  );
                });
            } else {
              await staticPages
                .createStaticPage(params)
                .then((res) => res.json())
                .then(() => {
                  this.$message.success(
                    'Статичная страница успешно создана :)',
                  );
                  this.$router.push(staticPagesRoute);
                });
            }
          }
        },
      );
    },

    async getStaticPageById(): Promise<any> {
      await staticPages
        .getStaticPageById(this.id)
        .then((res) => res.json())
        .then(({ data }) => {
          this.page = data;
          this.page.status = Boolean(data.status);
        });
    },
  },
});
