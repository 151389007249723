import {get, post, deleteMethod, put} from './core';

const getArticlesList = (page: number): Promise<any> => get(`/api/admin/article?page=${page}`);
const createArticle = (params: any): Promise<any> => post('/api/admin/article', params, true);
const updateArticle = (newsId: number, params: any): Promise<any> => post(`/api/admin/article/${newsId}`, params, true);
const deleteArticle = (newsId: number): Promise<any> => deleteMethod(`/api/admin/article/${newsId}`);
const getArticleById = (newsId: number): Promise<any> => get(`/api/admin/article/${newsId}`);
const searchArticles = (params: any): Promise<any> => get('/api/admin/article/search', params);
const getArticleCategory = (): Promise<any> => get('/api/admin/article-category');
const searchArticleCategory = (params: any): Promise<any> => get(`/api/admin/article-category/search`, params);
const getArticleCategoryById = (id: number): Promise<any> => get(`/api/admin/article-category/${id}`);
const createCategoryArticle = (params: any): Promise<any> => post(`/api/admin/article-category`, params);
const updateCategoryArticle = (id: number, params: any): Promise<any> => put(`/api/admin/article-category/${id}`, params);
const deleteArticleCategory = (id: number) => deleteMethod(`/api/admin/article-category/${id}`);
export const restoreArticle = (id: number): Promise<any> => put(`/api/admin/article/${id}/restore`, {});
export const moderateArticle = (id: number, status: string, message: string = null): Promise<any> => put(`/api/admin/article/${id}/moderate`, {
    status,
    message,
});

export const articles = {
    getArticlesList,
    createArticle,
    updateArticle,
    deleteArticle,
    getArticleById,
    searchArticles,
    getArticleCategory,
    searchArticleCategory,
    getArticleCategoryById,
    createCategoryArticle,
    updateCategoryArticle,
    deleteArticleCategory,
};

export default {articles};
