var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-form', {
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      "form": _vm.form
    }
  }, [_c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.action) + " ")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-spin', {
    attrs: {
      "spinning": false
    }
  }, [_c('div', {
    staticClass: "spin-content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Слаг (Пример: введенная строка `slug` создаст ссылку `mysite.com/page/slug`) "
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['slug', {
        initialValue: _vm.baseCategory.slug,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'slug',\n                    {\n                      initialValue: baseCategory.slug,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Введите название (не более 150 символов)"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Родительская категория "
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['parent_id', {
        initialValue: _vm.baseCategory.parent_id,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'parent_id',\n                    {\n                      initialValue: baseCategory.parent_id,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Категория"
    }
  }, [_c('a-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Не выбрано")]), _vm._l(_vm.articlesCategories, function (category) {
    return _c('a-select-option', {
      key: category.id,
      attrs: {
        "value": String(category.id)
      }
    }, [_vm._v(" " + _vm._s(category.translations.ru.title) + " ")]);
  })], 2)], 1)], 1)], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_c('a-card', {
    attrs: {
      "title": "Локализация"
    }
  }, [_c('a-tabs', {
    attrs: {
      "type": "editable-card",
      "hide-add": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_vm._l(_vm.LOCALE_LIST, function (_ref) {
    var locale = _ref.locale,
      title = _ref.title;
    return [_c('a-tab-pane', {
      key: locale,
      attrs: {
        "tab": title,
        "closable": false
      }
    }, [_c('a-row', {
      attrs: {
        "gutter": 24
      }
    }, [_c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A (".concat(locale, ")")
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".title"), {
          initialValue: _vm.baseCategory.translations[locale] ? _vm.baseCategory.translations[locale].title : undefined,
          rules: [{
            required: true
          }]
        }],
        expression: "[\n                          `translations.${locale}.title`,\n                          {\n                            initialValue: baseCategory.translations[locale] ? baseCategory.translations[locale].title : undefined,\n                            rules: [\n                              {\n                                required: true\n                              }\n                            ]\n                          }\n                        ]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Введите название (не более 150 символов)"
      }
    })], 1)], 1)], 1), _c('a-row', {
      attrs: {
        "gutter": 24
      }
    }, [_c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Seo Title (".concat(locale, ")")
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".seo_title"), {
          initialValue: _vm.baseCategory.translations[locale] ? _vm.baseCategory.translations[locale].seo_title : undefined,
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                            `translations.${locale}.seo_title`,\n                              {\n                                initialValue: baseCategory.translations[locale] ? baseCategory.translations[locale].seo_title : undefined,\n                                rules: [\n                                  {\n                                    required: false\n                                  }\n                                ]\n                              }\n                          ]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Введите title"
      }
    })], 1)], 1)], 1), _c('a-row', {
      attrs: {
        "gutter": 24
      }
    }, [_c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Seo Description (".concat(locale, ")")
      }
    }, [_c('a-textarea', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".seo_description"), {
          initialValue: _vm.baseCategory.translations[locale] ? _vm.baseCategory.translations[locale].seo_description : undefined,
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                            `translations.${locale}.seo_description`,\n                            {\n                              initialValue: baseCategory.translations[locale] ? baseCategory.translations[locale].seo_description : undefined,\n                              rules: [\n                                {\n                                  required: false\n                                }\n                              ]\n                            }\n                          ]"
      }],
      staticStyle: {
        "height": "145px"
      },
      attrs: {
        "size": "large",
        "placeholder": "Введите description"
      }
    })], 1)], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("Сохранить")])], 1)], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }