
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/Global/PageTitle.vue';
import { mapState, mapGetters } from 'vuex';
import { applications } from '@/api';

@Component({
  name: 'Platforms',
  components: { PageTitle },
  computed: mapState(['platformTypes']),
})
export default class Platforms extends Vue {

  columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },

    {
      title: 'Название',
      dataIndex: 'label',
      key: 'name',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
    },
  ];
  private platformTypes: any[];

  private deletePLatformType(id: number): void {
    this.$confirm({
      title: 'Вы действительно хотите удалить платформу?',
      content:
        'Вы не сможете восстановить его после удаления, только добавить еще раз.',
      onOk: async () => {
        await applications.deletePlatformType(id).then(() => {
          this.$message.success('Платформа успешно удалена :)', 2);
          this.$store.dispatch('GET_PLATFORM_TYPES');
        });
      },
    });
  }
}
