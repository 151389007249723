var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-modal', {
    attrs: {
      "title": "Модерация статьи",
      "visible": true,
      "confirmLoading": _vm.loading
    },
    on: {
      "cancel": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('a-button', {
          key: "back",
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v(" Закрыть ")]), _vm.article.status === _vm.ARTICLE_STATUS_AWAITING_MODERATION ? _c('a-button', {
          key: "submit",
          attrs: {
            "type": "danger",
            "loading": _vm.loading
          },
          on: {
            "click": _vm.decline
          }
        }, [_vm._v(" Отклонить ")]) : _vm._e(), ![_vm.ARTICLE_STATUS_PUBLISHED].includes(_vm.article.status) ? _c('a-button', {
          key: "submit",
          attrs: {
            "type": "primary",
            "loading": _vm.loading
          },
          on: {
            "click": _vm.publish
          }
        }, [_vm._v(" Опубликовать ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('a-spin', {
    attrs: {
      "spinning": _vm.loading
    }
  }, [_vm.article.moderations.length ? [_c('a-list', {
    staticClass: "comment-list",
    attrs: {
      "item-layout": "horizontal",
      "data-source": _vm.article.moderations
    },
    scopedSlots: _vm._u([{
      key: "renderItem",
      fn: function fn(item, index) {
        return _c('a-list-item', {}, [_c('a-comment', [_c('a', {
          attrs: {
            "slot": "author"
          },
          slot: "author"
        }, [_vm._v(_vm._s(item.user.name))]), _c('p', {
          attrs: {
            "slot": "content"
          },
          slot: "content"
        }, [_vm._v(" " + _vm._s(item.message) + " ")]), _c('a-tooltip', {
          attrs: {
            "slot": "datetime",
            "title": _vm.moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')
          },
          slot: "datetime"
        }, [_c('span', [_vm._v(_vm._s(_vm.moment(item.created_at).fromNow()))])])], 1)], 1);
      }
    }], null, false, 312451961)
  })] : _vm._e(), _c('a-textarea', {
    staticStyle: {
      "height": "150px",
      "margin-bottom": "20px"
    },
    attrs: {
      "placeholder": "Оставьте ваш комментарий"
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }