import { render, staticRenderFns } from "./TagListings.vue?vue&type=template&id=444a44c4&scoped=true"
import script from "./TagListings.vue?vue&type=script&lang=js"
export * from "./TagListings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "444a44c4",
  null
  
)

export default component.exports