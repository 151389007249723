
// @ts-nocheck
import { mapState } from 'vuex';
import { Component, Vue, Prop } from 'vue-property-decorator';
import PageTitle from '@/components/Global/PageTitle.vue';
import { applications } from '@/api';
import { osRoute } from '@/components/Applications/routes';

@Component({
  name: 'ChangeOs',
  components: { PageTitle },
  computed: mapState(['platformTypes']),
})
export default class ChangeOs extends Vue {
  @Prop() public id!: number;

  platformTypes: [];
  action: string = '';
  title: string = '';

  baseOs: any = {
    id: 1,
    name: '',
    seo_name: '',
    label: '',
    enabled: 0,
    platform_type_id: 0,
    created_at: '',
    updated_at: '',
    is_mobile: 0,
  };

  async getOsById(): Promise<any> {
    await applications
      .getOsById(this.id)
      .then((res) => res.json())
      .then(({ data }) => {
        this.baseOs = data;
        this.baseOs.platform_type_id = data.platform_type.id;
      });
  }

  async handleSubmit(): Promise<any> {

    this.baseOs.is_mobile = `${Number(this.baseOs.is_mobile)}`;
    this.baseOs.enabled = Number(this.baseOs.enabled);

    if (this.id) {
      await applications
        .updateOs(this.id, this.baseOs)
        .then((res) => res.json())
        .then(this.handleErrors('Операционная система успешно обновлена :)'))
        .catch(() =>
          this.$message.error('Ошибка обновления операционной системы :('),
        );
    } else {
      await applications
        .createOs(this.baseOs)
        .then((res) => res.json())
        .then(this.handleErrors('Операционная система успешно создана :)'))
        .catch(() =>
          this.$message.error('Ошибка создания операционной системы :('),
        );
    }
  }

  /**
   * @description Обработка ошибок
   * @param {string} message
   * @return {function}
   */
  handleErrors(message: string) {
    return ({ errors, code }: any) => {
      if (errors && code === 422) {
        Object.values(errors).forEach((values: any) => {
          values.forEach((value: string) => {
            this.$message.error(value);
          });
        });
      } else {
        this.$message.success(message, 2);
        this.$store.dispatch('GET_OPERATION_SYSTEMS');

      }
    };
  }

  mounted() {
    if (this.id) {
      this.action = 'Сохранить';
      this.title = 'Редактировать операционную систему';
      this.getOsById();
    } else {
      this.action = 'Добавить';
      this.title = 'Добавить операционную систему';
    }
  }
}
