var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Комментарии приложений"
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'commentsAppsCreate'
      }
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "icon": "plus",
      "type": "primary"
    }
  }, [_vm._v(" Добавить комментарий ")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', [_c('h3', [_vm._v("Поиск")])]), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": 6
    }
  }, [_c('a-form', {
    attrs: {
      "form": _vm.form
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleSubmit.apply(null, arguments);
      }
    }
  }, [_c('a-input', {
    attrs: {
      "placeholder": "Поиск",
      "size": "large"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
        return _vm.handleSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search.text,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "text", $$v);
      },
      expression: "search.text"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": 6
    }
  }, [_c('a-form-item', [_c('a-select', {
    attrs: {
      "show-search": "",
      "allow-clear": "",
      "value": _vm.userNameQuery,
      "placeholder": "Имя автора",
      "default-active-first-option": false,
      "show-arrow": false,
      "filter-option": false,
      "not-found-content": _vm.usersSearchDataSearching ? undefined : 'Не найден',
      "size": "large"
    },
    on: {
      "search": _vm.handleUserNameSearch,
      "change": _vm.handleUserNameChange
    }
  }, [_vm.usersSearchDataSearching ? _c('a-spin', {
    attrs: {
      "slot": "notFoundContent",
      "size": "small"
    },
    slot: "notFoundContent"
  }) : _vm._e(), _vm._l(_vm.usersSearchData, function (user) {
    return _c('a-select-option', {
      key: user.id
    }, [_vm._v(" " + _vm._s(user.name) + " ")]);
  })], 2)], 1)], 1), _c('a-col', {
    attrs: {
      "span": 6
    }
  }, [_c('a-form-item', [_c('a-select', {
    attrs: {
      "show-search": "",
      "allow-clear": "",
      "value": _vm.appNameQuery,
      "placeholder": "Название приложения",
      "default-active-first-option": false,
      "show-arrow": false,
      "filter-option": false,
      "not-found-content": _vm.appsSearchDataSearching ? undefined : 'Не найдено',
      "size": "large"
    },
    on: {
      "search": _vm.handleAppNameSearch,
      "change": _vm.handleAppNameChange
    }
  }, [_vm.appsSearchDataSearching ? _c('a-spin', {
    attrs: {
      "slot": "notFoundContent",
      "size": "small"
    },
    slot: "notFoundContent"
  }) : _vm._e(), _vm._l(_vm.appsSearchData, function (app) {
    return _c('a-select-option', {
      key: app.id
    }, [_vm._v(" " + _vm._s(app.name) + " ")]);
  })], 2)], 1)], 1), _c('a-col', {
    attrs: {
      "span": 5
    }
  }, [_c('a-form-item', [_c('a-select', {
    attrs: {
      "placeholder": "Выберите статус",
      "size": "large"
    },
    model: {
      value: _vm.search.status,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "status", $$v);
      },
      expression: "search.status"
    }
  }, [_c('a-select-option', {
    key: "0",
    attrs: {
      "value": ""
    }
  }, [_vm._v("Выберите статус ")]), _c('a-select-option', {
    key: "2",
    attrs: {
      "value": "1"
    }
  }, [_vm._v("Активно")]), _c('a-select-option', {
    key: "3",
    attrs: {
      "value": "2"
    }
  }, [_vm._v("Спам")]), _c('a-select-option', {
    key: "4",
    attrs: {
      "value": "3"
    }
  }, [_vm._v("Не спам")])], 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "7"
    }
  }, [_c('a-range-picker', {
    attrs: {
      "size": "large",
      "placeholder": ['Начало', 'Конец']
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  })], 1), _c('a-col', {
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      "span": 24
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-right": "25px"
    },
    attrs: {
      "icon": "search",
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("Поиск ")]), _c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.resetFilter
    }
  }, [_vm._v("Сбросить")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "pagination": false,
      "dataSource": _vm.comments.data,
      "loading": _vm.hasLoadComments,
      "rowKey": "id",
      "rowClassName": _vm.rowClassName
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "operation",
      fn: function fn(id, review) {
        return [_c('a-row', [_c('a', {
          attrs: {
            "href": review.url,
            "target": "_blank"
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "eye"
          }
        })], 1), _c('router-link', {
          attrs: {
            "to": {
              name: 'CommentsAppsChange',
              params: {
                commentId: review.id
              }
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1), _c('a-tooltip', [_c('template', {
          slot: "title"
        }, [_vm._v(" Ответить ")]), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "primary",
            "icon": "form"
          },
          on: {
            "click": function click($event) {
              return _vm.openAnswerModal(review);
            }
          }
        })], 2), review.markAsSpam === 0 ? _c('a-tooltip', [_c('template', {
          slot: "title"
        }, [_vm._v(" Отметить как спам ")]), review.markAsSpam === 0 ? _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "danger",
            "icon": "pause-circle"
          },
          on: {
            "click": function click($event) {
              return _vm.handleSetCommentToSpam(review.id);
            }
          }
        }) : _vm._e()], 2) : _c('a-tooltip', [_c('template', {
          slot: "title"
        }, [_vm._v(" Убрать из спама ")]), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "primary",
            "icon": "play-circle"
          },
          on: {
            "click": function click($event) {
              return _vm.removeCommentFromSpam(review.id);
            }
          }
        })], 2), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "danger",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.handleRemoveComment(review.id);
            }
          }
        })], 1)];
      }
    }, {
      key: "rating",
      fn: function fn(rating) {
        return [_c('p', {
          staticStyle: {
            "color": "#1BA305"
          }
        }, [_vm._v(_vm._s(rating))])];
      }
    }, {
      key: "user",
      fn: function fn(user) {
        return [user ? _c('a-button', {
          attrs: {
            "type": "link"
          },
          on: {
            "click": function click($event) {
              return _vm.filterByUser(user.id, user.name);
            }
          }
        }, [_vm._v(" " + _vm._s(user.name) + " ")]) : _vm._e()];
      }
    }, {
      key: "text",
      fn: function fn(text) {
        return [text.length > 150 ? _c('p', [_vm._v(_vm._s(text.slice(0, 150)) + " ...")]) : _vm._e(), text.length < 150 ? _c('p', [_vm._v(_vm._s(text))]) : _vm._e()];
      }
    }, {
      key: "application",
      fn: function fn(application) {
        return [application ? _c('a-button', {
          attrs: {
            "type": "link"
          },
          on: {
            "click": function click($event) {
              return _vm.filterByApplication(application.id, application.name);
            }
          }
        }, [_vm._v(" " + _vm._s(application.name) + " ")]) : _vm._e()];
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [!_vm.hasLoadComments && _vm.comments.meta ? _c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.comments.meta.total,
      "pageSize": _vm.comments.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.comments.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.comments.meta, "current_page", $$v);
      },
      expression: "comments.meta.current_page"
    }
  }) : _vm._e()], 1)], 1), _vm.answerReview ? _c('comments-answer-modal', {
    attrs: {
      "review": _vm.answerReview
    },
    on: {
      "close": _vm.closeAnswerModal,
      "created": _vm.handleSubmit
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }