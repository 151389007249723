
// @ts-nocheck
import { Component, Vue, Prop } from 'vue-property-decorator';
import PageTitle from '@/components/Global/PageTitle.vue';
import { License } from '@/types';
import { applications } from '@/api';
import { licensesRoute } from '@/components/Applications/routes';
import pickBy from 'lodash/pickBy';

@Component({
  name: 'EditLicense',
  components: { PageTitle },
})
export default class EditLicense extends Vue {
  @Prop() public id!: number;
  title: string = '';
  action: string = '';

  baseLicense: License = {
    name: '',
    label: '',
    description: '',
  };

  mounted() {
    if (this.id) {
      this.action = 'Сохранить';
      this.title = 'Редактирование лицензии';
      applications
        .getLicenseTypeById(this.id)
        .then((res) => res.json())
        .then(({ data }) => {
          this.baseLicense = data;
        });
    } else {
      this.action = 'Создать';
      this.title = 'Добавление лицензии';
    }
  }

  /**
   * @description Создание или редактирование лицензии
   * @return {Promise<any>}
   */
  private async saveLicenseType(): Promise<any> {
    // Проверим обязательные поля
    const { name, label } = this.baseLicense;
    // @ts-ignore TODO
    const params: License = pickBy(this.baseLicense);

    if (!(name.length && label.length)) {
      this.$message.info(
        'Поля Название, Уникальное название обязательны к заполнению',
      );
      return;
    }

    if (this.id) {
      await applications
        .updateLicenseType(this.id, params)
        .then(() => {
          this.$message.success('Тип лицензии успешно обновлен :)', 2);
          this.$store.dispatch('GET_LICENSE_TYPES');
        })
        .catch(() => {
          this.$message.error(
            'Произошла ошибка обновления лицензии, попробуйте позже :(',
            2,
          );
        });
    } else {
      await applications
        .createLicenseType(params)
        .then(() => {
          this.$message.success('Тип лицензии успешно добавлен :)', 2);
          this.$router.push(licensesRoute);
          this.$store.dispatch('GET_LICENSE_TYPES');
        })
        .catch(() => {
          this.$message.error(
            'Произошла ошибка добавления лицензии, попробуйте позже :(',
            2,
          );
        });
    }
  }
}
