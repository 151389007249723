<template>
  <div>
    <PageTitle title="Статьи">
      <router-link to="/articles/add">
        <a-button size="large" type="primary" icon="plus">Добавить статью</a-button>
      </router-link>
    </PageTitle>
    <a-layout-content class="page__content">
      <a-row>
        <h3>Поиск</h3>
      </a-row>
      <a-row :gutter="24">
        <a-col span="6">
          <a-input
            size="large"
            v-model="search.title"
            placeholder="Название"
            v-on:keyup.enter="handleSubmit"
          ></a-input>
        </a-col>

        <a-col :span="5">
          <a-form-item>
            <a-select
              placeholder="Выберите статус"
              size="large"
              v-model="search.status"
            >
              <a-select-option :value="null" key="0">Все</a-select-option>
              <a-select-option value="deleted" key="1">В корзине</a-select-option>
              <template v-for="(label, value) in ARTICLE_STATUS_LABELS">
                <a-select-option :value="value" :key="value">{{ label }}</a-select-option>
              </template>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="5">
          <a-select
            size="large"
            showSearch
            placeholder="Автор"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="searchDevelopers"
            notFoundContent="Введите имя разработчика..."
            v-model="search.author_id"
          >
            <a-select-option
              v-for="developer in developersList"
              :key="developer.id"
              :value="developer.id"
              >{{ developer.name }}</a-select-option
            >
          </a-select>
        </a-col>

        <a-col span="8">
          <a-range-picker
            :placeholder="['Начало', 'Конец']"
            size="large"
            v-model="dates"
          ></a-range-picker>
        </a-col>
        <a-col span="24" style="margin-top: 25px">
          <a-button
            size="large"
            type="primary"
            icon="search"
            style="margin-right: 25px"
            @click="handleSubmit"
            >Поиск</a-button>
          <a-button size="large" @click="resetFilter">Сбросить</a-button>
        </a-col>
      </a-row>
    </a-layout-content>

    <a-layout-content class="page__content">
      <a-table
        :columns="columns"
        :pagination="false"
        :dataSource="articles.data"
        :loading="hasLoadArticles"
        rowKey="id"
        @change="onChange"
      >
        <template slot="categories" slot-scope="categories, record">
          <a-tag
            v-if="categories.data"
            v-for="category in categories.data"
            :key="category.id"
            color="blue"
          >
            {{ category.translations.ru.title }}
          </a-tag>
        </template>

        <template #status="status, record">
          <template v-if="record.deleted_at">
            <a-tag color="red">
              В корзине
            </a-tag>
          </template>
          <template v-else-if="status === ARTICLE_STATUS_DRAFT">
            <a-tag color="orange">
              {{ ARTICLE_STATUS_LABELS[ARTICLE_STATUS_DRAFT] }}
            </a-tag>
          </template>
          <template v-else-if="status === ARTICLE_STATUS_PUBLISHED">
            <a-tag color="green">
              {{ ARTICLE_STATUS_LABELS[ARTICLE_STATUS_PUBLISHED] }}
            </a-tag>
          </template>
          <template v-else-if="status === ARTICLE_STATUS_AWAITING_MODERATION">
            <a-tag color="blue">
              {{ ARTICLE_STATUS_LABELS[ARTICLE_STATUS_AWAITING_MODERATION] }}
            </a-tag>
          </template>
          <template v-else-if="status === ARTICLE_STATUS_REJECTED">
            <a-tag color="red">
              {{ ARTICLE_STATUS_LABELS[ARTICLE_STATUS_REJECTED] }}
            </a-tag>
          </template>
        </template>

        <template #actions="id, record">
          <a
            v-if="record.deleted_at && record.status === ARTICLE_STATUS_PUBLISHED"
            :href="record.urlArticle" target="_blank"
          >
            <a-button
              type="primary"
              class="gray-btn"
              icon="eye"
              style="margin-right: 10px"
            />
          </a>

          <template v-if="record.deleted_at">
            <a-button
              type="dashed"
              class="gray-btn"
              icon="undo"
              style="margin-right: 10px"
              @click="restore(record.id)"
            />
          </template>

          <template v-else>
            <router-link :to="{ path: `/articles/${record.id}/edit` }">
              <a-button
                type="primary"
                class="gray-btn"
                icon="edit"
                style="margin-right: 10px"
              />
            </router-link>

            <a-button
              type="danger"
              class="gray-btn"
              icon="delete"
              style="margin-right: 10px"
              @click="deleteArticle(record.id)"
            />
          </template>

        </template>

        <template v-slot:author="author">
          {{ author.name }}
        </template>

        <template v-slot:name="translations">
          {{ translations?.ru?.title ?? translations[Object.keys(translations)[0]].title }}
        </template>
      </a-table>

      <a-row style="text-align: right;">
        <a-pagination
          v-if="articles.meta"
          @change="paginationChange"
          v-model="articles.meta.current_page"
          :total="articles.meta.total"
          :pageSize="articles.meta.per_page"
          style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import { debounce } from 'lodash';
import { users } from '@/api/users';
import {articles, restoreArticle} from '@/api/articles';
import {
  ARTICLE_STATUS_AWAITING_MODERATION,
  ARTICLE_STATUS_DRAFT,
  ARTICLE_STATUS_LABELS,
  ARTICLE_STATUS_PUBLISHED,
  ARTICLE_STATUS_REJECTED
} from "@/global/constants";

export default {
  name: 'ArticlesList',

  components: { PageTitle },

  props: {
    status: {
      required: false,
      default: null,
    },
  },

  data() {
    return {
      searchDevelopers: debounce(this.handleDevelopersSearch, 300),
      developersList: [],
      articles: {},
      dates: [],
      search: {
        title: undefined,
        author_id: undefined,
        status: this.status,
        page: 1,
        sortField: 'created_at',
        sortOrder: 'desc',
      },
      hasLoadArticles: false,
      columns: [
        {
          title: 'Название',
          dataIndex: 'translations',
          key: 'title',
          scopedSlots: { customRender: 'name' },
          ellipsis: true,
          width: 350,
        },
        {
          title: 'Автор',
          dataIndex: 'author',
          key: 'author',
          scopedSlots: { customRender: 'author' },
          sorter: true,
          sortDirections: ['desc', 'asc'],
        },
        {
          title: 'Категория',
          dataIndex: 'categories',
          key: 'categories',
          scopedSlots: { customRender: 'categories' },
        },
        {
          title: 'Статус',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Создана',
          dataIndex: 'created_at',
          key: 'created_at',
          sorter: true,
          defaultSortOrder: 'descend',
        },
        {
          title: 'Опубликована',
          dataIndex: 'published_at',
          key: 'published_at',
          sorter: true,
        },
        {
          title: 'Действия',
          dataIndex: '',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
        },
      ],
      ARTICLE_STATUS_DRAFT,
      ARTICLE_STATUS_PUBLISHED,
      ARTICLE_STATUS_AWAITING_MODERATION,
      ARTICLE_STATUS_REJECTED,
      ARTICLE_STATUS_LABELS,
    };
  },
  mounted() {
    this.handleSubmit();
  },
  methods: {
    resetFilter() {
      this.dates = [];
      this.search = {
        title: undefined,
        author_id: undefined,
        status: this.status,
        page: 1,
        sortField: 'created_at',
        sortOrder: 'desc',
      };
      this.handleSubmit();
    },

    onChange(pagination, filters, sorter) {
      const { order, field } = sorter;
      this.search.sortField = field;
      this.search.sortOrder = order ? order.replace('end', '') : null;
      this.handleSubmit();
    },

    async handleSubmit(page = 1) {
      this.hasLoadArticles = true;
      const { title, author_id } = this.search;
      const [dateStart, dateEnd] = this.dates;

      const params = {
        title,
        author_id,
        page,
        publishedAt: {
          start: dateStart && dateStart.format('YYYY-MM-DD'),
          end: dateEnd && dateEnd.format('YYYY-MM-DD'),
        },
        [`order[${this.search.sortField}]`] : this.search.sortOrder,
      };

      if (this.search.status === 'deleted') {
        params.deleted_at = 1;
      } else {
        params.status = this.search.status;
        params.deleted_at = 0;
      }

      await articles
        .searchArticles(params)
        .then((res) => res.json())
        .then((data) => {
          this.articles = data;
          this.hasLoadArticles = false;
        });
    },

    async handleDevelopersSearch(value) {
      await users
        .searchUsers({
          name: value,
        })
        .then((res) => res.json())
        .then(({ data }) => {
          this.developersList = data;
        });
    },

    deleteArticle(id) {
      this.$confirm({
        title: `Вы действительно хотите удалить данную статью?`,
        content: 'Вы сможете восстановить ее после удаления.',
        onOk: async () => {
          await articles.deleteArticle(id).then(() => {
            this.$message.success('Статья успешна удалена', 3);
            this.handleSubmit();
          });
        },
      });
    },

    paginationChange(page) {
      window.scrollTo(0, 0);
      this.search = { ...this.search, page };
      this.handleSubmit(page);
    },

    restore(id) {
      this.$confirm({
        title: `Вы действительно хотите восстановить данную статью?`,
        onOk: async () => {
          await restoreArticle(id).then(() => {
            this.$message.success('Статья успешна восстановлена', 3);
            this.handleSubmit();
          });
        },
      });
    },
  },
};
</script>
