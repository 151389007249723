<template>
  <div>
    <AddPageTitle
      :title="title"
      :id="id || moderationId"
    />
    <ModerationTop v-if="moderationId" :appId="moderationId"/>
    <DeveloperModeration v-if="id" :appId="id"/>
    <a-layout-content class="page__content">
      <TopMenu
        :id="id"
        :moderationId="moderationId"
        active="tag_lists"
      />

      <a-tabs type="card">
        <a-tab-pane key="1" tab="Категории">
          <TagListingsList
            :id="id||moderationId"
            :loading="loadingAppTagListings"
            :tagListings="appTagListings"
            @removed="onRemoved"
          />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Добавить категорию">
          <TagListingAdd
            :id="id||moderationId"
            :loading="loadingAllTagListings"
            :tagListings="allTagListings"
            :exclude="appTagListings"
            @added="onAdded"
          />
        </a-tab-pane>
      </a-tabs>
    </a-layout-content>
  </div>
</template>

<script>
  import PageTitle from '../../Global/PageTitle';
  import TopMenu from '../Add/TopMenu';
  import ModerationTop from '../../Moderation/Applications/ModerationTop.vue';
  import DeveloperModeration from '../../Moderation/Applications/DeveloperModeration.vue';
  import { applications as appApi } from '@/api';
  import TagListingAdd from '@/components/Applications/TagListings/TagListingAdd';
  import TagListingsList from '@/components/Applications/TagListings/TagListingsList';
  import {getTagListings} from '@/api/tagListing';
  import AddPageTitle from '@/components/Applications/Add/AddPageTitle';

  export default {
    name      : 'TagListings',
    props     : ['id', 'moderationId'],
    components: {
      AddPageTitle,
      TagListingAdd,
      PageTitle,
      TopMenu,
      ModerationTop,
      DeveloperModeration,
      TagListingsList,
    },
    data() {
      return {
        appTagListings       : [],
        loadingAppTagListings: true,

        allTagListings       : [],
        loadingAllTagListings: true,
      };
    },
    computed  : {
      title() {
        if (this.moderationId) {
          return 'Модерация приложения';
        }
        return 'Редактирование категорий для приложения';
      },
    },
    created() {
      this.loadingAppTagListings = true;

      appApi.getTagListings(this.id)
        .then((res) => res.json())
        .then(({ data }) => {
          this.appTagListings = data;
        }).finally(() => {
        this.loadingAppTagListings = false;
      });

      appApi.getAppById(this.id)
        .then((res) => res.json())
        .then(({ data }) => {
          this.fetchAllTagListings(data.operationSystem.id);
        });
    },
    methods   : {
      fetchAllTagListings(osId) {
        this.loadingAllTagListings = true;

        return getTagListings({ os_id: osId })
          .then((res) => res.json())
          .then(({ data }) => {
            this.allTagListings = data;
          })
          .finally(() => {
            this.loadingAllTagListings = false;
          });
      },
      onRemoved(tagId) {
        const index = this.appTagListings.findIndex((tagListing) => tagListing.id === tagId);
        if (-1 === index) {
          return;
        }
        this.appTagListings.splice(index, 1);
      },
      onAdded(tagListing) {
        let index = this.appTagListings.findIndex((tag) => tag.id > tagListing.id);

        if (-1 === index) {
          index = this.appTagListings.length;
        }

        if (0 === index) {
          index = 1;
        }

        this.appTagListings.splice(index, 0, tagListing);
      },
    },
  };
</script>

<style scoped>

</style>
