var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Новости"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/news/add"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "plus"
    }
  }, [_vm._v("Добавить новость")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', [_c('h3', [_vm._v("Поиск")])]), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "5"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Название"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search.title,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "title", $$v);
      },
      expression: "search.title"
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 5
    }
  }, [_c('a-form-item', [_c('a-select', {
    attrs: {
      "placeholder": "Выберите статус",
      "size": "large"
    },
    model: {
      value: _vm.search.status,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "status", $$v);
      },
      expression: "search.status"
    }
  }, [_c('a-select-option', {
    key: "0",
    attrs: {
      "value": null
    }
  }, [_vm._v("Все")]), _c('a-select-option', {
    key: "1",
    attrs: {
      "value": "deleted"
    }
  }, [_vm._v("В корзине")]), _c('a-select-option', {
    key: "2",
    attrs: {
      "value": "published"
    }
  }, [_vm._v("Опубликовано")]), _c('a-select-option', {
    key: "3",
    attrs: {
      "value": "draft"
    }
  }, [_vm._v("Черновик")])], 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "5"
    }
  }, [_c('a-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Автор",
      "showSearch": "",
      "showArrow": false,
      "size": "large",
      "notFoundContent": "Введите имя разработчика...",
      "value": _vm.value,
      "filterOption": false
    },
    on: {
      "search": _vm.searchDevelopers
    },
    model: {
      value: _vm.search.author_id,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "author_id", $$v);
      },
      expression: "search.author_id"
    }
  }, _vm._l(_vm.developersList, function (developer) {
    return _c('a-select-option', {
      key: developer.id,
      attrs: {
        "value": developer.id
      }
    }, [_vm._v(_vm._s(developer.name))]);
  }), 1)], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-range-picker', {
    attrs: {
      "placeholder": ['Начало', 'Конец'],
      "size": "large"
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  })], 1), _c('a-col', {
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-right": "25px"
    },
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "search"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("Поиск")]), _c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.resetFilter
    }
  }, [_vm._v("Сбросить")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "pagination": false,
      "dataSource": _vm.news.data,
      "loading": _vm.hasLoadNews,
      "rowKey": "id"
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "categories",
      fn: function fn(categories, record) {
        return _vm._l(categories.data, function (category) {
          return categories.data ? _c('a-tag', {
            key: category.id,
            attrs: {
              "color": "blue"
            }
          }, [_vm._v(" " + _vm._s(category.translations.ru.title) + " ")]) : _vm._e();
        });
      }
    }, {
      key: "status",
      fn: function fn(status, record) {
        return [record.deleted_at ? [_c('a-tag', {
          attrs: {
            "color": "red"
          }
        }, [_vm._v(" В корзине ")])] : status === _vm.NEWS_STATUS_DRAFT ? [_c('a-tag', {
          attrs: {
            "color": "orange"
          }
        }, [_vm._v(" " + _vm._s(_vm.NEWS_STATUS_LABELS[_vm.NEWS_STATUS_DRAFT]) + " ")])] : status === _vm.NEWS_STATUS_PUBLISHED ? [_c('a-tag', {
          attrs: {
            "color": "green"
          }
        }, [_vm._v(" " + _vm._s(_vm.NEWS_STATUS_LABELS[_vm.NEWS_STATUS_PUBLISHED]) + " ")])] : _vm._e()];
      }
    }, {
      key: "actions",
      fn: function fn(id, record) {
        return [_c('a', {
          attrs: {
            "href": record.urlNews,
            "target": "_blank"
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "eye"
          }
        })], 1), record.deleted_at ? [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "dashed",
            "icon": "undo"
          },
          on: {
            "click": function click($event) {
              return _vm.restore(record.id);
            }
          }
        })] : [_c('router-link', {
          attrs: {
            "to": {
              path: "/news/".concat(record.id, "/edit")
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1), _c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteNews(record.id);
            }
          }
        })]];
      }
    }, {
      key: "author",
      fn: function fn(author) {
        return [_vm._v(" " + _vm._s(author.name) + " ")];
      }
    }, {
      key: "name",
      fn: function fn(translations) {
        var _translations$ru$titl, _translations$ru;
        return [_vm._v(" " + _vm._s((_translations$ru$titl = translations === null || translations === void 0 ? void 0 : (_translations$ru = translations.ru) === null || _translations$ru === void 0 ? void 0 : _translations$ru.title) !== null && _translations$ru$titl !== void 0 ? _translations$ru$titl : translations[Object.keys(translations)[0]].title) + " ")];
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm.news.meta ? _c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.news.meta.total,
      "pageSize": _vm.news.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.news.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.news.meta, "current_page", $$v);
      },
      expression: "news.meta.current_page"
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }